import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PrimasCustomIconService extends MatIconRegistry {
  constructor(
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
    private errorHandler: ErrorHandler
  ) {
    super(httpClient, domSanitizer, document, errorHandler);
    console.log('MyCustomIconRegistry instantiated!');
    this.addSvgIcon('click-up', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/clickup.svg'));
    this.addSvgIcon('outlook-send', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/outlook-send.svg'));
    this.addSvgIcon('outlook-reply', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/outlook-reply.svg'));
    this.addSvgIcon('seo-audit', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/seo-audit.svg'));
    this.addSvgIcon('task-type', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/task-type-icon.svg'));
    this.addSvgIcon('google-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/google-icon.svg'));
    this.addSvgIcon('office365-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/office365-icon.svg'));
    this.addSvgIcon('h1-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/h1.svg'));

    // Add more icons as needed
  }
}
