import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import { RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { CategorySeoRequestModel } from 'src/app/shared/models/request/category-seo-request.model';
import { Media, ProfileSeo, SeoInfo } from '../../profile-management/profile-detail.model';
import { CategoryManagementService } from '../category-management.service';
import { AddCategoryModel, FAQsModel, HyperDatas } from './add-category.model';
import baguetteBox from 'baguettebox.js';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { SettingService } from 'src/app/shared/services/setting.service';
import { Setting } from 'src/app/shared/models/setting.model';
import { SettingCompanyViewModel } from '../../setting-management/setting-company/setting-company';
import { ResizeImageComponent } from 'src/app/shared/components/stand-alone-component/resize-image/resize-image.component';
import { Helper } from 'src/app/shared/utility/Helper';
import { MergeCategoryModel } from '../category.model';
import { ProfileService } from '../../profile-management/profile.service';
import { FfcUrlColor } from '../../profile-management/profile-detail/seo-tab/seo-info/seo-info.component';
import { MultiMediaGalleryComponent } from 'src/app/shared/components/multi-media-gallery/multi-media-gallery.component';
import { DropDownValue, TableOfContent } from '../../summary-report/summary-report.component';
import { distinctUntilChanged, filter, map, pairwise, startWith, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { SubmitIndexingDialogComponent } from '../../profile-management/profile-detail/seo-tab-table/submit-indexing-dialog/submit-indexing-dialog.component';
import { GoogleSearchQueryRequestViewModel } from '../../indexing/indexing.model';
import { IndexingService } from '../../indexing/indexing.service';
import { ImpressionChartDialogComponent } from '../../profile-management/profile-detail/seo-tab-table/impression-chart-dialog/impression-chart-dialog.component';
import { HotToastService } from '@ngneat/hot-toast';
import { TagService } from 'src/app/shared/components/stand-alone-component/auto-complete-tag/tag.service';
import { Tags } from 'src/app/shared/components/stand-alone-component/auto-complete-tag/tag.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ReturnResult } from 'src/app/shared/models/return-result';
import { AutoCompleteCategoriesService } from 'src/app/shared/components/stand-alone-component/auto-complete-categories/auto-complete-categories.service';
import { SeoCategoryHistoryComponent } from '../seo-category-history/seo-category-history.component';
import { countWordValidators } from '../../seo-dashboard/keyword-mapping-tool/add-edit-keyword-mapping-tool/countWordValidator';
import { AddEditCategoryLocationComponent } from '../../wplocation-management/add-edit-category-location/add-edit-category-location.component';
import { NbAccessChecker } from '@nebular/security';
import { SeoInfoService } from '../../seo-info-management/seo-info.service';
import { RevisionVersionService } from '../../revision-version-group-management/revision-version.service';
import { RevisionStatus, RVRevisionMeta } from '../../revision-version-group-management/revision-version.model';
import { RevisionVersionManagementComponent } from '../../revision-version-group-management/revision-version-management/revision-version-management.component';
import * as mark from 'mark.js';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-edit-category',
  templateUrl: './add-edit-category.component.html',
  styleUrls: ['./add-edit-category.component.scss']
})
export class AddEditCategoryComponent implements OnInit, OnDestroy {
  @ViewChild('uploadImg') uploadDialog: TemplateRef<any>;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('location') location: AddEditCategoryLocationComponent;
  @ViewChild('publishCheckbox') publishCheckbox: TemplateRef<any>;
  @ViewChild('lockCheckbox') lockCheckbox: TemplateRef<any>;
  @ViewChild('rejectReasonTemplate') rejectReasonTemplate: TemplateRef<any>;
  @ViewChild('contentCategory') contentCategory: ElementRef;

  public reenableButton = new EventEmitter<boolean>();

  action: TblActionType;
  categoryModel: AddCategoryModel;
  frmCategory: FormGroup;
  isLoading = false;
  gallery: any;
  media: Media;
  rawImage: File;
  jsonArray: any[];
  inputForm = this.frmBuilder.group({
    rawImage: ['', RxwebValidators.extension({ extensions: ['png', 'jpg', 'jpeg', 'gif'] })]
  });
  mimeType: string[] = ['image/png', 'image/jpg', 'image/jpeg'];
  submitLoading = false;
  convertedFile: any;
  dialogRefImage: MatDialogRef<any, any>;
  imageChangedEvent: any = '';
  isShowTools = false;
  loading = false;
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  transform: ImageTransform = {};
  containWithinAspectRatio = false;
  showCropper = false;
  isSaveEnter = false;
  isRefresh = null;
  displayImage = SettingCompanyViewModel.golbalDefaultAvatar;
  defaultAvatar = SettingCompanyViewModel.golbalDefaultAvatar;
  editorOptions = QuillConfiguration;
  editorBlogs = { toolbar: [...QuillConfiguration.toolbar.filter((x: any) => !x.includes('link'))] };
  isEditor = false;
  isEditorTopDes = false;
  isEditorAnswer = true;
  crmMode = '1';
  domains: Setting;
  disableLink = false;
  isGifUpload: boolean = false;
  mergeModel: MergeCategoryModel;
  ffcUrlState: string = null;
  ffcUrlColor = FfcUrlColor
  hyperDataLst: HyperDatas[];
  selectedIndex: string = "category";
  isChange: boolean = false;
  isNoIndexState: boolean = false;
  isPublishNow = true;
  tableOfContent: TableOfContent[] = [
    { text: "Category Information", value: "category", statusControlName: 'active' },
    { text: "SEO Information", value: "seo" },
    { text: "FAQs", value: "faqs" },
    {
      text: "Event Entertainment Inspiration", value: "inspiration",
      children: [
        { text: "Left Blog", value: "leftBlog", statusControlName: 'firstActive' },
        { text: "Right Blog", value: "rightBlog", statusControlName: 'secondActive' },
      ]
    },
    { text: "Ask An Expert", value: "askAnExpert", statusControlName: 'askAnExpertActive' },
  ];

  tableOfContentLst = [];
  showRequestIndexing: string = '';
  isGettingImpression: boolean = false;
  impression: number = null;
  impressionColor: string = null;
  impressionDate: Date = new Date();
  impressionRange: number = 7;
  focusSEOAudit = {
    "Input": 'seo',
    "Title tag": 'seo',
    "Meta description": 'seo',
    "Page Headings summary": 'category',
    "Keyword density": 'seo',
    "Related keywords": 'seo',
    "Image analysis": 'category'
  }
  myTags: Tags[] = [];
  loadingTag: boolean = false;
  tagsObservable = new Observable<Tags[]>();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagsList: string[] = [];
  maxWord: number = 6;
  isReadonlyKeyword: boolean = false;
  isLockCategory: boolean = false;
  private _destroy: Subject<void> = new Subject<void>();
  numberTruncateToggle: number = 15;
  inPendingRevision: RVRevisionMeta;
  revisionLoading: boolean = false;
  publishedRevision: RVRevisionMeta;
  isPublishedRevisionLoading: boolean = true;
  isChangePublishRevisionLoading: boolean = true;
  isChangePublishRevisionAndCategoryDetail: boolean = false;
  lastDetectedDate: Date = new Date();
  isCheckLockCategory: boolean = false;
  previousRejectRevision: RVRevisionMeta;

  markOptions = {
    "element": "mark",
    "className": "action-ai-detect-focus",
    "accuracy": "exactly",
    "separateWordSearch": false,
  };
  focusAICheck = {
    "ReasonBio": 'reason&bio',
  };
  markInstance;
  refreshColorExternalTab: boolean = false;
  isAIDetectionGranted$: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialModalRef: MatDialogRef<AddEditCategoryComponent>,
    private frmBuilder: RxFormBuilder,
    private toast: NbToastrService,
    private cdref: ChangeDetectorRef,
    private service: CategoryManagementService,
    private dialog: MatDialog,
    private domainService: SettingService,
    private profileService: ProfileService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private indexService: IndexingService,
    private toastService: HotToastService,
    private tagsService: TagService,
    private autoCompleteCategorySerive: AutoCompleteCategoriesService,
    private access: NbAccessChecker,
    private seoService: SeoInfoService,
    private revisionService: RevisionVersionService,
    private router: Router
  ) {
    this.showRequestIndexing = this.data.model?.showRequestIndexing;
    if (Helper.isNullOrEmpty(this.showRequestIndexing)) {
      this.domainService.getSettingByKeyAndGroup("SHOW_REQUEST_INDEXING", "SEO").subscribe(resp => {
        if (resp?.result?.value) {
          this.showRequestIndexing = resp?.result?.value?.trim();
        }
      })
    }
    this.handlePermission();
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  handlePermission() {
    this.access.isGranted('view', "location-category").subscribe(result => {
      if (result) {
        const findLocation = this.tableOfContent.findIndex(x => x.text == "Location");
        if (findLocation == -1) this.tableOfContent.push({ text: "Location", value: "location" });
      }
    });

    this.access.isGranted('view', 'request-AI-checker').subscribe({
      next: (response) => {
        this.isAIDetectionGranted$ = response;
      },
    });
  }
  ngOnInit() {
    this.setupData();
    this.dialModalRef.updatePosition({ right: '0', });
    this.dialModalRef.updateSize(this.action != 0 && this.action != 5 ? '1200px' : '600px', '100vh');
    // this.domainService.domainList().subscribe(domains => {
    //   this.domains = domains;
    // });
    this.domainService.getSettingByKeyAndGroup("IS_CRM_MODE", "SYSTEM").subscribe(crmMode => {
      if (crmMode.result) {
        this.crmMode = crmMode.result?.value;
        if (this.crmMode == "1") {
          this.dialModalRef.updateSize('600px', '100vh');
          this.frmCategory?.removeControl('friendlyUrl');
        }
        else {
          this.frmCategory?.get('isCrmMode').setValue(true);
        }
      }
    });

    this.checkKeywordPermission();

    this.domainService.getSettingByKeyAndGroup("FONT-FACING", "DOMAIN").subscribe(e => {
      if (e.result) {
        this.domains = e.result;
      }
    });
    this.numberTruncateToggle = window.innerWidth > 1440 ? 20 : 1;

    this.getPreviousRejectedRevision(this.data?.model?.categoryId);
  }

  ngOnDestroy(): void {
    if (this._destroy) {
      this._destroy.next();
      this._destroy.complete();
    }
  }

  checkKeywordPermission() {
    this.access.isGranted("view", "keyword-seo-information").pipe(take(1)).subscribe(isAccess => {
      this.isReadonlyKeyword = !isAccess;
    })
  }

  async closeDialog(value: boolean) {
    if (this.isChange) {
      const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
        data: { message: 'Do you wish to close this popup? You will lose your unsaved data.' }
      });

      var result = await dialogConfirm.afterClosed().toPromise();
      if (!result) return;
    }

    this.dialModalRef.close(value);
  }

  saveData(isCheck?: boolean) {
    if (this.frmCategory.valid && (this.isSaveEnter || isCheck)) {
      if (!this.frmCategory.valid) {
        this.toast.warning('You must fill empty required fields', 'Warning');
        return;
      }
      this.isRefresh = true;
      if (this.crmMode !== '1') {
        this.checkIsQuestion();
      }
      const tagsData = this.tagsList?.join(",");
      this.frmCategory.controls['tags'].setValue(tagsData);
      if (this.action === 0) {
        // add:
        //  TODO: parse form modal to model:
        //console.log(this.frmCategory.value);
        this.isRefresh = true;
        const model = new CategorySeoRequestModel();
        model.category = { ...this.frmCategory.value };
        model.seoInfo = { ...this.frmCategory.value };
        model.seoInfo.description = this.frmCategory.get('seoDescription').value;
        model.IsUpdateSeo = false;
        model.category.faqs = this.convertToObject(this.frmCategory.get('faqsList').value);
        model.category.media = model.category.media?.mediaId ? model.category.media : null;
        model.seoInfo.noIndex = false;
        this.service.addCategoryAsync(model).subscribe(res => {
          if (res.result) {
            this.isChange = false;
            this.toast.success('Add Category successfully', 'Success');
            if (this.crmMode == '1') this.closeDialog(true);
            else {
              if (this.action == 0) {
                this.data = { action: 1, model: res.result };
                // 2023_06_29 ducqm start fix issue not show parent Category
                if (this.categoryModel.parentId !== null) {
                  this.service.parentAlreadyCalled = false;
                  this.service.reFreshParentCategory('');
                }
                // 2023_06_29 ducqm end fix issue not show parent Category
                this.ngOnInit();
              }

              if (this.showRequestIndexing === "1" && this.frmCategory.controls.active.value) {
                this.dialog.open(SubmitIndexingDialogComponent, {
                  disableClose: false,
                  width: '400px',
                  data: { seoInfo: res.result?.seoInfo, type: "CATEGORY" }
                })
              }
            }
          }
        });
      } else if (this.action === 1) {
        this.isRefresh = true;
        const model = new CategorySeoRequestModel();
        model.category = Object.assign({}, this.frmCategory.value, { categoryId: this.categoryModel.categoryId, seoInfoId: this.categoryModel.seoInfoId });
        model.seoInfo = { ...this.frmCategory.value };
        model.IsUpdateSeo = true;
        model.seoInfo.description = this.frmCategory.get('seoDescription').value;
        model.seoInfo.seoInfoId = this.categoryModel.seoInfoId;
        model.category.faqs = this.convertToObject(this.frmCategory.get('faqsList').value);
        model.category.media = model.category.media?.mediaId ? model.category.media : null;
        model.seoInfo.noIndex = this.isNoIndexState ?? false;
        this.service.updateCategoryAsync(model).subscribe(res => {
          if (res.result === true) {
            // Update category after saving
            this.autoCompleteCategorySerive.getCategoryById(this.categoryModel.categoryId).subscribe(async res => {
              if (res.result) {
                this.data.model = res.result;
                this.data.action = TblActionType.Edit;
                this.setupData();
                if (this.location) {
                  await this.location?.saveLocation({ locationUrl: `${this.frmCategory.value?.friendlyUrl}-[CATEGORY]`, mediaId: this.frmCategory.controls?.media.value?.mediaId, referenceId: this.categoryModel?.categoryId });
                }
              }
            });
            let currentTrack = this.isChange;
            this.isChange = false;
            if (res.message !== null) {
              // this.toast.warning(res.message, 'Warning');
            } else {
              if (this.categoryModel.parentId !== null) {
                this.service.parentAlreadyCalled = false;
                this.service.reFreshParentCategory('');
              }
              this.toast.success('Update Category successfully', 'Success');

              if (this.crmMode !== '1' && this.showRequestIndexing === "1" && this.frmCategory.controls.active.value
                && currentTrack) {
                let data = { ...model.seoInfo, ffcUrl: this.data.model.seoInfo.ffcUrl };
                if (model.seoInfo.friendlyUrl !== this.data.model.seoInfo.friendlyUrl) {
                  delete data['ffcUrl'];
                }

                this.dialog.open(SubmitIndexingDialogComponent, {
                  disableClose: false,
                  width: '400px',
                  data: { seoInfo: data, type: "CATEGORY" }
                })
              }
            }
            // this.closeDialog(true);
            this.getTag(false);
          }

          this.reenableButton.emit();
        });
      } else if (this.action === 5) {
        const model = new CategorySeoRequestModel();
        model.category = { ...this.frmCategory.value };
        model.seoInfo = { ...this.frmCategory.value };
        model.seoInfo.description = this.frmCategory.get('seoDescription').value;
        model.IsUpdateSeo = false;
        model.category.faqs = this.convertToObject(this.frmCategory.get('faqsList').value);
        model.category.media = model.category.media?.mediaId ? model.category.media : null;
        let mergeModel: MergeCategoryModel = this.data.mergeModel;
        model.seoInfo.noIndex = false;
        this.service.mergeWithCreateNewCategory(mergeModel.sources, mergeModel.destination, model).subscribe(res => {
          if (res.result) {
            this.isChange = false;
            this.toastService.warning(`The process is still merging in the background. Please double-check in a minute`);
            if (this.crmMode == '1') this.closeDialog(true);
            else {
              if (this.action == 5) {
                this.data = { action: 1, model: res.result };
                this.ngOnInit();
              }
            }
          }
        });
      }
    }
  }
  getParentId(value: string) {
    let parentId = value === '' ? null : value;
    if (parentId === '-1') {
      parentId = null;
    }
    this.frmCategory.controls.parentId?.patchValue(parentId);
  }

  openDialog() {
    if (this.categoryModel.categoryId != null) {
      this.dialogRefImage = this.dialog.open(this.uploadDialog, {
        panelClass: 'dialog-avatar',
      });

    } else {
      this.toast.warning('Category not existed. Please create category!', 'Warning');
    }
  }

  handleFileInput(event) {
    this.isGifUpload = false;
    if (event) this.inputForm.patchValue({ 'rawImage': event[0] });

    setTimeout(() => {
      var type = this.inputForm.controls.rawImage.value?.type ?? undefined;
      if (type == 'image/gif') {
        this.isGifUpload = true;
      } else {
        if (!this.inputForm.get('rawImage').valid || !this.mimeType.includes(type)) {
          this.submitLoading = false;
          this.toast.danger('Invalid Input type. Please check again!', 'Error');
          return;
        } else this.dialogRefImage.close();

        let componentRef = this.dialog.open(ResizeImageComponent, {
          disableClose: true,
          data: {
            aspectRatio: 4 / 3,
            canvasRotation: 0,
            transform: {},
            showCropper: false,
            containWithinAspectRatio: false,
            type: 'file',
            file: this.inputForm.controls.rawImage.value,
          }
        });

        componentRef.componentInstance.getImage.subscribe((res: Blob) => {
          if (res) {
            this.inputForm.patchValue({ 'rawImage': new File([res], event[0].name) });
          } else {
            //this.toast.danger('Cannot get image cropper', 'Error');
          }
        }, err => {
          this.toast.danger('Cannot get image from server', 'Error');
        });

        componentRef.afterClosed().subscribe(res => {
          if (res) this.onUpload()
        })
      }
    }, 200)
  }

  showImage(event) { }

  onUpload() {
    try {
      this.submitLoading = true;
      if (this.inputForm.controls.rawImage.value) {
        const file = this.inputForm.controls.rawImage.value as File;
        this.service.changeCategoryImage(this.categoryModel.categoryId, file).subscribe(resp => {
          //console.log(resp);
          if (resp.result !== null) {
            this.media = resp.result;
            this.isRefresh = true;
            this.submitLoading = false;
            this.isGifUpload = false;
            this.categoryModel.media = this.media;
            const imageName = this.media?.media1?.split(/(\\|\/)/g)?.pop()?.split(".")[0] || '';
            this.setUpImageNameValidator()
            try {
              this.frmCategory?.controls?.imageName?.setValue(imageName);
              if (this.frmCategory?.controls?.media) {
                this.frmCategory?.controls?.media?.['controls']?.mediaId?.setValue(this.categoryModel?.media?.mediaId);
                this.frmCategory?.controls?.media?.['controls']?.title?.setValue(this.categoryModel?.media?.title);
                this.frmCategory?.controls?.media?.['controls']?.media1?.setValue(this.categoryModel?.media?.media1);
              }
            } catch (error) {
              console.log(error);
            }
            this.toast.success('Change image successfully', 'Success');
            this.closeDialogUploadImage();
          }
        });
      } else {
        this.toast.danger('Cropped image is empty, please try again.', 'Error');
        this.submitLoading = false;
      }
    } catch (error) {
      this.toast.danger('Fail to convert image. please try another image', 'Error');
      this.submitLoading = false;
    }
  }

  closeDialogUploadImage(): void {
    this.dialogRefImage.close();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.rawImage = event.target.files[0];
    this.inputForm.setValue({ rawImage: event.target.files[0] });
    this.isShowTools = true;
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
  cropperReady(sourceImageDimensions: Dimensions) {
    //console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  imageLoaded() {
    this.showCropper = true;
    //console.log('Image loaded');
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    //console.log(event, base64ToFile(event.base64));
    this.convertedFile = base64ToFile(this.croppedImage);
  }

  listenEventKeyPressEnter(event) {
    if (event.keyCode === 13) {
      this.isSaveEnter = true;
    }
  }

  changeStatusEditor() {
    this.isEditor = !this.isEditor;
    if (this.frmCategory.value.description == null || this.frmCategory.value.description === '') {
      this.frmCategory.patchValue({
        description: 'Add content...'
      });
    }
  }
  // changeStatusEditorAnswer(frmGroup: FormGroup){
  //   // this.isEditorAnswer = !this.isEditorAnswer;
  //   let data = frmGroup.value;
  //   if(data.answer == null || data.answer === ''){
  //     frmGroup.get('answer').setValue('Add content...');
  //   }

  // }
  changeStatusEditorTopDes() {
    this.isEditorTopDes = !this.isEditorTopDes;
    if (this.frmCategory.value.topDescription == null || this.frmCategory.value.topDescription === '') {
      this.frmCategory.patchValue({
        topDescription: 'Add content...'
      });
    }
  }
  openSeoLink(target) {
    if (target.value && target.value.trim() !== '') {

      if (this.domains !== undefined) {
        window.open(`${this.domains.value}/categories/${target.value}`, '_blank');
      }
    }
  }

  async selectMedia(fromControl) {
    const dialog = this.dialog.open(MultiMediaGalleryComponent, {
      data: {
        profileId: this.categoryModel.categoryId,
        typeMediaType: 1,
        selectedItem: this.frmCategory.controls[fromControl]?.value,
        displayName: this.frmCategory.controls?.categoryName?.value
      }
    });

    const resultAfterClose = await dialog.afterClosed().toPromise();
    if (resultAfterClose)
      this.frmCategory.controls[fromControl]
        .patchValue(resultAfterClose);
  }

  async getHyperData() {
    this.isLoading = true;
    const data = await this.service.getHyperDataByReferenceId(this.categoryModel.categoryId).toPromise();
    if (data && data.result) {
      this.hyperDataLst = data.result;
      const askAnExpert = data.result.find(x => x.hyperType == 'ASK_AN_EXPERT');
      const inspiration = data.result.filter(x => x.hyperType == 'INSPIRATION');

      if (askAnExpert) {
        // this.frmCategory.patchValue({
        //   askAnExpertDescription: askAnExpert.hyperDescription,
        //   askAnExpertImage: askAnExpert.media,
        //   askAnExpertActive: askAnExpert.active
        // })
        this.categoryModel.askAnExpertImage = askAnExpert.media;
        this.categoryModel.askAnExpertActive = askAnExpert.active;
        this.categoryModel.askAnExpertDescription = askAnExpert.hyperDescription;
      }

      if (inspiration) {
        let first = inspiration.find((x, i) => i == 0);
        let second = inspiration.find((x, i) => i == 1);
        // this.frmCategory.patchValue({
        //   firstHyperImage: first?.media,
        //   firstHyperLink: first?.hyperLink,
        //   firstHyperDescription: first?.hyperDescription,
        //   firstActive: first?.active || false,
        //   secondHyperImage: second?.media,
        //   secondHyperLink: second?.hyperLink,
        //   secondHyperDescription: second?.hyperDescription,
        //   secondActive: second?.active || false,
        // })
        this.categoryModel.firstHyperImage = first?.media;
        this.categoryModel.firstHyperLink = first?.hyperLink;
        this.categoryModel.firstHyperDescription = first?.hyperDescription;
        this.categoryModel.firstActive = first?.active || false;
        this.categoryModel.secondHyperImage = second?.media;
        this.categoryModel.secondHyperLink = second?.hyperLink;
        this.categoryModel.secondHyperDescription = second?.hyperDescription;
        this.categoryModel.secondActive = second?.active || false;
      }
    }
    this.isLoading = false;
  }

  setDesc(quill) {
    if (quill && quill.container) {
      quill.container.classList.add("custom-desc-blog");
      // const content = quill.getText();
      // if (!content || content == '\n' || content.length == 0) {
      //   quill.format('size', '14px');
      //   quill.format('color', 'white');
      //   quill.format('font', 'Raleway');
      //   setTimeout(() => document.getElementById('category')
      //     .scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }), 100);
      // }
    }
  }

  changeFormat(quill) {
    if (quill && quill.editor) {
      const content = quill.editor.getText();
      if (!content || content == '\n' || content.length == 0) {
        quill.editor.format('size', '14px');
        quill.editor.format('color', 'white');
        quill.editor.format('font', 'Raleway');
      }
    }
  }

  scroll(target: string) {
    this.selectedIndex = target;
    document.getElementById(target).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  async setupData() {
    this.tableOfContentLst = this.convertTableOfContentToList(this.tableOfContent);
    this.action = this.data.action;
    this.media = this.data.model?.media || { media1: this.defaultAvatar };
    this.mergeModel = this.data.mergeModel || this.mergeModel;

    this.media.media1 = this.data?.model?.media?.media1;

    this.categoryModel = this.data.model ?? new AddCategoryModel();
    if (this.action === 1) {
      const newEditModel = new AddCategoryModel();
      newEditModel.convertSeoCategory(this.data.model);
      this.categoryModel.seoInfoId = newEditModel.seoInfoId;
      this.categoryModel.title = newEditModel.title;
      this.categoryModel.friendlyUrl = newEditModel.friendlyUrl;
      this.categoryModel.keywords = newEditModel.keywords;
      this.categoryModel.seoDescription = newEditModel.seoDescription;
      this.categoryModel.faqsList = newEditModel.faqsList;
      this.categoryModel.primaryKeyword = newEditModel.primaryKeyword;
      this.categoryModel.secondaryKeyword = newEditModel.secondaryKeyword;
      this.categoryModel.imageName = newEditModel.imageName;
      this.categoryModel.noIndex = newEditModel.noIndex;
      this.isNoIndexState = newEditModel.noIndex;
      // this.categoryModel.active = newEditModel.active;

      if (this.categoryModel.active && this.categoryModel.friendlyUrl) {
        this.ffcUrlState = this.data.model?.seoInfo.ffcUrl?.state;
        if (!this.ffcUrlState) {
          const model = {
            seoInfo: this.data.model.seoInfo
          } as ProfileSeo;

          this.profileService.bindingFfcUrl(model).subscribe({
            next: (resp) => {
              if (resp?.result) {
                if (resp.result.state === "NOTINDEXED") {
                  this.ffcUrlState = "Not Indexed";
                }
                else {
                  this.ffcUrlState = Helper.toTitlecase(resp.result.state);
                }
              }
              else {
                this.ffcUrlState = "Not On Google";
              }
            },
            complete: () => {
              // this.refreshData();
            }
          })
        }
      }
      this.getCurrentAndPublishedCategoryComparison();
    }
    // if (this.frmCategory && this.frmCategory?.value && !(this.frmCategory?.value?.faqsList && this.frmCategory?.value?.faqsList?.length > 0)) {
    //   this.addDetail();
    // }

    if (this.action == TblActionType.Edit) {
      await this.getHyperData();
      this.getPendingRevision();
    }

    this.frmCategory = this.frmBuilder.formGroup(AddCategoryModel, this.categoryModel);
    this.setUpImageNameValidator()


    this.frmCategory.valueChanges.pipe(
      takeUntil(this._destroy),
      pairwise(),
      filter(([prev, cur]) => JSON.stringify(prev) != JSON.stringify(cur)))
      .subscribe((event) => this.isChange = true);

    var regexLink = /<a(.*?)<\/a>/g;
    this.frmCategory.get('firstHyperDescription').valueChanges.pipe(
      takeUntil(this._destroy),
      distinctUntilChanged(),
      filter((value: string) => regexLink.test(value))
    ).subscribe((value: string) => {
      var fixValue = value.replace("<a", "<span").replace("</a>", "</span>");
      this.frmCategory.patchValue({ firstHyperDescription: fixValue });
    });

    this.frmCategory.get('secondHyperDescription').valueChanges.pipe(
      takeUntil(this._destroy),
      distinctUntilChanged(),
      filter((value: string) => regexLink.test(value))
    ).subscribe((value) => {
      var fixValue = value.replace("<a", "<span").replace("</a>", "</span>");
      this.frmCategory.patchValue({ secondHyperDescription: fixValue });
    });

    if (this.data.model?.active) {
      let showImpression = this.data.model?.showImpression;
      if (showImpression == null || showImpression == undefined) {
        const showImpressionSetting = await this.domainService.getSettingByKeyAndGroup("SHOW_IMPRESSION", "SEO").toPromise();
        if (showImpressionSetting?.result?.value?.trim() === "1") {
          showImpression = true;
        }
      }

      if (showImpression) {
        this.impression = this.data.model.impressions;
        this.impressionDate = this.data.model.impressionEndDate;
        this.impressionRange = this.data.model.impressionDateRange;

        if (this.impression == null || this.impression == undefined) {
          this.impression = await this.getImpression(this.data.model?.seoInfo?.ffcUrl?.url);
        }

        this.impressionColor = this.changeColorDisplay(this.impression, "impressions");
      }
    }
    await this.getTag();
    this.setUpKeywordValidator();
    this.checkLockCategory();

    this.getPublishRevisionDetail();
  }

  checkLockCategory() {
    this.isLockCategory = this.data?.model?.isLocked;
    if (this.isLockCategory) {
      this.isLockCategory = true;
      this.frmCategory.reset();
      this.frmCategory.disable();
      this.location.lockCategory();
    }
  }

  handleScroll(element): void {
    const { scrollHeight, scrollTop, clientHeight } = element.target;
    const scrollPosition = scrollTop || 0;
    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 75) {
      this.selectedIndex = this.tableOfContentLst[this.tableOfContentLst.length - 1].value;
      return;
    }


    for (let i = this.tableOfContentLst.length - 1; i >= 0; i--) {
      const element = this.elementRef.nativeElement.querySelector(`#${this.tableOfContentLst[i].value}`);
      if (element) {
        if (scrollPosition > element.offsetTop - 75) {
          this.selectedIndex = this.tableOfContentLst[i].value;
          break;
        }
      }
    }
  }
  convertTableOfContentToList(tableOfContent: TableOfContent[]) {
    let tableOfContentLst = [];
    for (let i = 0; i < tableOfContent.length; i++) {
      tableOfContentLst.push(tableOfContent[i]);
      if (tableOfContent[i].children) {
        const tables = this.convertTableOfContentToList(tableOfContent[i].children);
        tableOfContentLst = tableOfContentLst.concat(tables);
      }
    }
    return tableOfContentLst;
  }
  async getImpression(url: string) {
    if (url) {
      this.isGettingImpression = true;

      await this.getImpressionDate();

      let gscRequest = new GoogleSearchQueryRequestViewModel();
      // get end date from setting
      gscRequest.endDate = this.impressionDate;
      // start date will be end date minus date range from setting (days)
      gscRequest.startDate = new Date(this.impressionDate.getTime() - (this.impressionRange * 24 * 60 * 60 * 1000));
      gscRequest.url = url;

      const gscRespond = await this.indexService.getOverViewIndexBlock(gscRequest).toPromise();
      this.isGettingImpression = false;
      if (gscRespond?.result?.rows[0]?.impressions != null) {
        return gscRespond.result.rows[0].impressions;
      }
    }
    return 0;
  }
  changeColorDisplay(lengthCharacter: number, propName: string): string {
    var colorChange = "";
    switch (propName) {
      case 'impressions':
        if (lengthCharacter >= 0 && lengthCharacter < 200) {
          colorChange = '#000000';
        }
        else if (lengthCharacter >= 200 && lengthCharacter < 300) {
          colorChange = '#FFD966';
        }
        else {
          colorChange = 'green';
        }
        break;
    }
    return colorChange;
  }
  async openImpressionDialog() {
    if (this.impression != null) {
      await this.getImpressionDate();

      this.dialog.open(ImpressionChartDialogComponent, {
        disableClose: true,
        width: '1200px',
        data: {
          ...this.data.model,
          impressionEndDate: this.impressionDate,
          impressionStartDate: new Date(this.impressionDate.getTime() - (this.impressionRange * 24 * 60 * 60 * 1000)),
          type: "CATEGORY",
          domainUrl: this.domains.value,
          hyperDataList: this.hyperDataLst
        }
      })
    }
  }

  async getImpressionDate() {
    if (this.impressionDate == null || this.impressionDate == undefined) {
      // get end date
      const endDate = await this.domainService.getSettingByKeyAndGroup("IMPRESSION_END_DATE", "SEO").toPromise();
      if (endDate?.result?.value) {
        this.impressionDate = new Date(Date.parse(endDate.result.value))
      }
      else {
        this.impressionDate = new Date();
      }
    }

    if (this.impressionRange == null || this.impressionRange == undefined) {
      // get date range
      const dateRange = await this.domainService.getSettingByKeyAndGroup("IMPRESSION_DATE_RANGE", "SEO").toPromise();
      if (dateRange?.result?.value) {
        this.impressionRange = parseInt(dateRange.result.value)
      }
      else {
        this.impressionRange = 7;
      }
    }
  }
  convertToObject(model: FAQsModel[]) {
    const jsonArray: string = JSON.stringify(model);
    return jsonArray;
  }
  convertToFAQsList(data: string): FAQsModel[] {
    try {
      const dataObj = data;
      this.jsonArray = JSON.parse(dataObj);
      const modelArray: FAQsModel[] = this.jsonArray.map(item => {
        return {
          question: item.question,
          answer: item.answer,
        } as FAQsModel;
      })
      return modelArray;
    }
    catch (ex) {
      console.log(ex);
    }
  }
  addDetail(detect: boolean = false) {
    try {
      var addMoreDetail = true;
      let details = this.frmCategory?.controls?.faqsList as FormArray
      if (details) {
        for (let i = 0; i < details.length; i++) {
          if (details.value[i].question == null && details.value[i].answer == null) {
            addMoreDetail = false;
          }
        }
        if (addMoreDetail) {
          let item = new FAQsModel();
          let frmGroup = this.frmBuilder.formGroup(item);
          details.push(frmGroup);
          if (detect && frmGroup) {
            frmGroup.valueChanges
              .pipe(takeUntil(this._destroy))
              .subscribe(resp => this.isChange = true);
          }
        }
      }
    }
    catch (ex) {
      console.log(ex);
    }
  }
  removeDetail(index: number) {
    try {
      if (index >= 0) {
        let details = this.frmCategory?.controls?.faqsList as FormArray
        if (details) {
          details.removeAt(index);
          // if(details.length == 0){
          //   this.addDetail(true);
          // }
        }
      }
    }
    catch (ex) {
      console.log(ex);
    }
  }
  checkIsQuestion() {
    let details = this.frmCategory?.controls?.faqsList.value as FormArray
    if (details != null && details.length >= 0) {
      for (let i = 0; i < details.length; i++) {
        if (!details[i].question.endsWith('?'))
          details[i].question = details[i].question.concat('?');
      }
    }
  }
  async getTag(addValueChangeTagSelected: boolean = true) {
    this.loadingTag = true;
    const tagValue: string = this.frmCategory.controls['tags']?.value;
    if (tagValue) {
      this.tagsList = tagValue.split(",").filter(x => x).map(x => x.trim());
    }
    const tagResult = await this.tagsService.getAllTags().toPromise();
    if (tagResult) this.myTags = tagResult.result;
    if (addValueChangeTagSelected) {
      this.tagsObservable = this.frmCategory.controls['tagSelected'].valueChanges.pipe(
        startWith(''), takeUntil(this._destroy),
        map((tag: string | null) => tag !== '' ? this._filter(tag) : this.myTags.slice()));
    }
    this.loadingTag = false;
  }
  onClearInput(value) {
    value = '';
    this.tagInput.nativeElement.value = '';
  }
  private _filter(value: string): Tags[] {
    const filterValue = value.toLowerCase();
    return this.myTags.filter(tag => tag.tagsName.toLowerCase().includes(filterValue));
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.tagsList.includes(event.option.viewValue)) {
      return;
    }
    this.tagsList.push(event.option.viewValue);
    this.frmCategory.controls['tagSelected'].setValue("");
    this.tagInput.nativeElement.value = '';
  }

  add(event: any): void {
    const value = (event.value || '').trim();
    if (this.tagsList.includes(value)) {
      return;
    }
    if (value) {
      this.tagsList.push(value);
    }
    this.frmCategory.controls['tagSelected'].setValue("");
    this.tagInput.nativeElement.value = '';
  }
  remove(tag: string) {
    const index = this.tagsList.indexOf(tag);
    if (index >= 0) {
      this.tagsList.splice(index, 1);
    }
  }

  focusSEOAuditElement: (key: string) => void =
    (key: string) => {
      try {
        let itemFocus = document.querySelector('.action-seo-focus');
        if (itemFocus) itemFocus.classList.remove('action-seo-focus');

        this.scroll(this.focusSEOAudit[key]);
        let ele = document.getElementById(this.focusSEOAudit[key])
          ?.querySelector('.category-section.altus-card');

        if (ele) ele.classList.add('action-seo-focus')
      }
      catch (ex) {
        console.log(ex);
      }
    }

  openUrlHistory() {
    let historyRef = this.dialog.open(SeoCategoryHistoryComponent, {
      disableClose: true,
      autoFocus: false,
      width: '35vw',
      data: {
        categoryId: this.categoryModel.categoryId
      }
    });
  }

  setUpImageNameValidator() {
    let frmControlImageName: FormControl = this.frmCategory.get('imageName') as FormControl;
    if (!this.media.mediaId) {
      frmControlImageName.clearValidators();
      frmControlImageName.updateValueAndValidity()
    } else {
      frmControlImageName.setValidators([
        RxwebValidators.pattern({ expression: { 'imageName': /^[a-zA-Z0-9\-_]+$/ }, message: 'Invalid format. Please use only letters, numbers, hyphens, and underscores.' }),
      ]);
      frmControlImageName.updateValueAndValidity();
    }
  }

  setUpKeywordValidator() {
    try {
      let formControlPrimaryKeyword: FormControl = this.frmCategory?.get('primaryKeyword') as FormControl;
      if (formControlPrimaryKeyword) {
        formControlPrimaryKeyword.clearValidators();
        formControlPrimaryKeyword.setValidators([
          countWordValidators(this.maxWord)
        ]);
        formControlPrimaryKeyword.updateValueAndValidity();
        this.frmCategory.updateValueAndValidity();
      }
    } catch (error) { }
  }

  async clickLockCategory() {
    const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
      data: { message: 'Do you wish to lock this category? The unsaved data will be lost.' }
    });

    var result = await dialogConfirm.afterClosed().toPromise();
    if (result) {
      this.service.updateLockStatusCategory(this.categoryModel.categoryId, true).subscribe(res => {
        if (res) {
          this.isRefresh = true;
          this.isLockCategory = true;
          this.frmCategory.reset();
          this.checkTagWhenLock();
          this.frmCategory.disable();
          this.location.lockCategory();
        }
      })
    }
  }

  async clickUnlockCategory() {
    const dialogConfirm = this.dialog.open(ConfirmModalComponent, {
      data: { message: 'Do you wish to unlock this category? This category may have been prepared for SEO scoring.' }
    });

    var result = await dialogConfirm.afterClosed().toPromise();
    if (result) {
      this.service.updateLockStatusCategory(this.categoryModel.categoryId, false).subscribe(res => {
        if (res) {
          this.isRefresh = true;
          this.isLockCategory = false;
          this.frmCategory.reset();
          this.frmCategory.enable();
          this.location.unlockCategory();
        }
      })
    }
  }

  private checkTagWhenLock() {
    // Get Old tags => set data
    var listTag = this.categoryModel.tags || "";
    this.tagsList = listTag.split(",").filter(x => x).map(x => x.trim());
  }
  async changeStateNoIndex(event: MouseEvent) {
    if (!this.isLockCategory) {
      event.preventDefault();
      event.stopPropagation();
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          message: `<h3 style="text-align:center; font-size:20px;"><i class="fa fa-exclamation-triangle" aria-hidden="true" style="color:#ffd107"></i> Warning!</h3><p>By turning this switch <b style="font-weight:900">${this.isNoIndexState ? 'Off' : 'On'}</b>, this category will be <b style="font-weight:900">${this.isNoIndexState ? 'Visible' : 'Invisible'}</b> to Google ranking. Please confirm your action</p>`,
          yesTitle: `I understood`,
          isDisableNoButton: true
        }
      })
      var result = await dialogRef.afterClosed().toPromise();
      if (result) {
        this.isNoIndexState = !this.isNoIndexState;
      }
    }

  }
  @HostListener('window:resize', ['$event'])
  onResizeTextSwitch(event) {
    if (event) {
      this.numberTruncateToggle = event.target.innerWidth > 1440 ? 20 : 1;
    }
  }
  async submitRevision() {
    const question = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to request to publish?`,
        externalTemplate: !this.isLockCategory ? this.lockCheckbox : undefined
      },
      disableClose: true,
    });

    const answer = await question.afterClosed().toPromise();
    if (answer) {
      this.revisionLoading = true;
      try {
        const responseResult = await this.revisionService.submitCategoryRevision(this.categoryModel.categoryId).toPromise();
        if (responseResult.result) {
          this.toast.success("Successfully submitted");
          this.inPendingRevision = responseResult.result;
          this.previousRejectRevision = undefined;
          this.getCurrentAndPublishedCategoryComparison();
        }
        if (this.isCheckLockCategory) {
          this.service.updateLockStatusCategory(this.categoryModel.categoryId, true).subscribe(res => {
            if (res) {
              this.isRefresh = true;
              this.isLockCategory = true;
              this.frmCategory.reset();
              this.checkTagWhenLock();
              this.frmCategory.disable();
              this.location.lockCategory();
            }
          })
          this.isCheckLockCategory = false;
        }
      }
      catch (ex) {
        console.error(ex);
      }
      this.revisionLoading = false;
    }
  }
  async getPendingRevision() {
    this.revisionLoading = true;
    try {
      const pendingRevision = await this.profileService.getPendingRevision(this.categoryModel.categoryId).toPromise();
      if (pendingRevision) {
        this.inPendingRevision = pendingRevision.result;
      }
    }
    catch (ex) {
      console.error(ex)
    }
    this.revisionLoading = false;
  }

  async getPreviousRejectedRevision(profileId: string) {
    if (this.data?.action == TblActionType.Edit) {
      this.revisionLoading = true;
      let objectType: string = "Category";
      try {
        const response = await this.profileService.getLatestRejectRevisionById(profileId, objectType).toPromise();
        if (response) {
          this.previousRejectRevision = response.result;
        }
      }
      catch (ex) {
        console.error(ex)
      }
      this.revisionLoading = false;
    }
  }

  async removeRevision() {
    const question = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to remove this vision?`
      },
      disableClose: true,
    });

    const answer = await question.afterClosed().toPromise();
    if (answer) {
      {
        try {
          this.revisionLoading = true;
          const pendingRevision = await this.profileService.removePendingRevision(this.categoryModel.categoryId).toPromise();
          if (pendingRevision.result) {
            this.toast.success("Successfully deleted");
            this.inPendingRevision = null;
            await this.getPreviousRejectedRevision(this.data?.model?.categoryId);
          }
        }
        catch (ex) {
          console.error(ex)
        }
        this.getCurrentAndPublishedCategoryComparison();
        this.revisionLoading = false;
      }
    }
  }
  async changeStatusRevision(status: RevisionStatus) {
    const question = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to reject this revision?`,
        externalTemplate: this.rejectReasonTemplate,
        externalTemplateContext: { row: this.inPendingRevision }
      },
      disableClose: true,
    });
    const answer = await question.afterClosed().toPromise();
    if (answer) {
      this.revisionLoading = true;
      const response = await this.revisionService.changeStatusRevision(this.inPendingRevision?.revisionId, status, false, this.inPendingRevision.description).toPromise();
      if (response.result) {
        this.toast.success(`Reject revision successfully`, "Reject Revision");
        this.refreshCategoryDataModel();
        this.getPublishRevisionDetail();
        await this.getPendingRevision();
        this.getCurrentAndPublishedCategoryComparison();
        this.isRefresh = true;
      }
      this.revisionLoading = false;
    }
  }
  async approveRevision() {
    let adminChange : boolean = false
    if(this.router.url.includes("configuration/revision-version")) {
      adminChange = true;
    }
    this.isPublishNow = true;
    const dialogApproveRevision = this.dialog.open(ConfirmModalComponent, {
      data: {
        message: `Do you wish to approve this revision?`,
        externalTemplate: this.publishCheckbox,
      }
    });
    let response = await dialogApproveRevision.afterClosed().toPromise();
    if (response) {
      try {
        this.revisionLoading = true;
        const response = await this.revisionService.changeStatusRevision(this.inPendingRevision?.revisionId, RevisionStatus.Approved, this.isPublishNow, undefined, undefined,adminChange).toPromise();
        if (response?.result) {
          this.toast.success("Approve revision successfully", "Approve Revision");
          this.refreshCategoryDataModel();
          this.getPublishRevisionDetail();
          await this.getPendingRevision();
          await this.getPreviousRejectedRevision(this.data?.model?.categoryId);
          this.getCurrentAndPublishedCategoryComparison();
          this.isRefresh = true;
        }
      }
      catch (ex) {
        console.error(ex)
      }
      this.revisionLoading = false;
    }
  }

  getPublishRevisionDetail() {
    this.isPublishedRevisionLoading = true;
    this.revisionService.getPublishedRevision(this.data?.model?.categoryId).subscribe({
      next: (response) => {
        this.publishedRevision = response.result;
      },
      complete: () => {
        this.isPublishedRevisionLoading = false;
      },
      error: () => {
        this.isPublishedRevisionLoading = false;
      }
    });
  }

  refreshCategoryDataModel() {
    this.loading = true;
    this.service.getCategoryById(this.data?.model?.categoryId).subscribe({
      next: (response) => {
        if (response?.result) {
          this.data.model = response.result;
          this.ngOnInit();
        }
      },
      complete: () => {
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  async getCurrentAndPublishedCategoryComparison() {
    this.isChangePublishRevisionLoading = true;
    const resultComparision = await this.revisionService.getCategoryComparision(this.data?.model?.categoryId).toPromise();
    if (resultComparision) {
      this.isChangePublishRevisionAndCategoryDetail = resultComparision?.result
    }
    this.lastDetectedDate = new Date();
    this.isChangePublishRevisionLoading = false;
  }

  onReloadDetectChange() {
    this.getCurrentAndPublishedCategoryComparison();
  }
  openRevisionManagement() {
    const defaultStatus = this.inPendingRevision ? RevisionStatus.WaitingForReview : RevisionStatus.Approved;
    const revisionDialog = this.dialog.open(RevisionVersionManagementComponent, {
      data: {
        id: this.categoryModel?.categoryId,
        revisionStatus: defaultStatus,
        revisionEntity: "Category",
      },
      width: '1400px',
    })
    revisionDialog.afterClosed().subscribe(e => {
      this.getCurrentAndPublishedCategoryComparison();
      this.getPendingRevision();
      this.getPublishRevisionDetail();
      this.getPreviousRejectedRevision(this?.categoryModel?.categoryId);
      this.autoCompleteCategorySerive.getCategoryById(this.categoryModel.categoryId).subscribe(async res => {
        if (res.result) {
          this.data.model = res.result;
          this.setupData();
          this.isRefresh = true;
        }
      });
    })
  }

  focusAICheckElement: (key: string, text: string) => void = (key: string, text: string) => {
    try {
      if (this.markInstance == null) {
        this.markInstance = new mark(this.contentCategory?.nativeElement);
      }

      this.markInstance.mark(text, this.markOptions);

      let elementHighlighted = document.querySelector('.action-ai-detect-focus');
      if (elementHighlighted) {
        elementHighlighted?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      setTimeout(() => {
        this.markInstance?.unmark(this.markOptions);
      }, 2500)
    }
    catch (ex) {
      console.log(ex);
    }
  }

  onAIDetectionChanged(value: boolean) {
    try {
      if (value) {
        this.isRefresh = value;
        // this.refreshColorExternalTab = true;
        // setTimeout(() => {
        //   this.refreshColorExternalTab = false;
        // }, 1000)
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }
}

