<mat-form-field appearance="standard">
    <mat-label>{{label}}</mat-label>
    <input type="text" matInput [formControl]="control" placeholder="{{placeholder}}" 
           [matAutocomplete]="auto" (focusout)="checkSelectedOption()" [readonly]="isReadOnly">
    <mat-autocomplete #auto="matAutocomplete" [nbSpinner]="loading" 
                      (optionSelected)="valueSelected($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option[displayKey]">
        {{option[displayKey]}}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="control.errors && (control.dirty ||control.touched)">
      {{errorMessage}}</mat-error>
  </mat-form-field>