<div class="d-flex flex-wrap" mat-dialog-title style="margin: unset;padding: 1px 10px 1px 28px;" fxLayoutAlign="space-between" [nbSpinner]="isLoading && location?.loading">
  <div fxLayout="row">
    <button mat-button color="primary" debouncedClick [throttleTime]="500" (click)="saveData(true)" [disabled]="!frmCategory?.valid || isLockCategory">
      <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
    </button>
    <button mat-button color="primary" (click)="closeDialog(isRefresh)" [disabled]="isLockCategory">
      <mat-icon>clear</mat-icon>Cancel
    </button>
    <button mat-button color="warn" *ngIf="isLockCategory && action == 1" debouncedClick [throttleTime]="500" (throttleClick)="clickUnlockCategory()">
      <mat-icon>lock</mat-icon>Unlock Category
    </button>
    <button mat-button style="color: #007100;" *ngIf="!isLockCategory && action == 1" debouncedClick [throttleTime]="500" (throttleClick)="clickLockCategory()">
      <mat-icon>lock_open</mat-icon>Lock Category
    </button>
    <nb-toggle class="no-index-toggle" *ngIf="action == 1" status="success" [disabled]="isLockCategory" [checked]="isNoIndexState" (click)="changeStateNoIndex($event)">
      <span [matTooltip]="numberTruncateToggle == 1 ? 'Active' : ''">
        {{'No Indexed' | truncateText:[numberTruncateToggle, '']}}
      </span>
      <mat-icon class="icon-help-noindex-c" matTooltip="This feature will affect the visibility of this category to Google! By turning this feature on, it will no longer be ranked in Google searches, but it will still be visible on the website. If you turn this feature off, you will signal to Google that this category should be ranked. So make serious decisions before using this feature">
        help_outline
      </mat-icon>
    </nb-toggle>
  </div>
  <div fxLayout="row">
    <div *ngIf="!inPendingRevision && action == 1 && crmMode != '1'" [nbSpinner]="revisionLoading">
      <button mat-raised-button color="primary" (click)="submitRevision()"  [disabled]="revisionLoading">
        Request to publish
      </button>
    </div>
    <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog(isRefresh)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-divider></mat-divider>
<mat-dialog-content (scroll)="handleScroll($event)" style="background: whitesmoke">
  <ng-container *ngIf="action == 5">
    <div class="alert alert-info" role="alert">
      You have selected {{mergeModel.sources.length}} categories. Please fill in new category to start merging.
    </div>
  </ng-container>
  <form [formGroup]="frmCategory" class="px-3" *ngIf="frmCategory">
    <div fxLayout="row" fxLayoutAlign="start start" fxFlexGap="24px">
      <ng-container *ngIf="action != 0 && action != 5  && crmMode !='1'">
        <div class="content-sticky" fxLayout="column" fxLayoutAlign="start start" fxFlex="30" style="gap: 8px;">

          <div class="w-100 alert alert-light" role="alert" style="margin: 0;">
            <ng-container *ngIf="!isPublishedRevisionLoading; else publishRevisionLabelLoader">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div *ngIf="publishedRevision; else noPublishedRevision">
                  <div class="w-100 publish-revision-detail-label">
                    <div class="w-100 d-flex text-success fw-600">
                      Published By: {{publishedRevision?.reviewer?.userName}}
                    </div>
                    <div class="w-100 text-success">
                      <span class="mr-1 fw-600">Last Published Date: {{publishedRevision?.reviewedDate | date: 'short'}} </span>
                    </div>
                  </div>
                </div>
                <ng-template #noPublishedRevision>
                  <div class="fw-600 w-100 text-center publish-revision-detail-label">
                    No revision has been published
                  </div>
                </ng-template>
                <div>
                  <button mat-icon-button [matMenuTriggerFor]="moreRevision" style="color: black"><mat-icon>more_horiz</mat-icon></button>
                  <mat-menu #moreRevision="matMenu">
                    <button mat-menu-item class="menu-action" (click)="openRevisionManagement()">
                      Open all revision
                    </button>
                  </mat-menu>
                </div>
              </div>
            </ng-container>
            <div *ngIf="(!inPendingRevision && previousRejectRevision)" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="8px" class="mt-2">
              <div class="notify-revision-category py-2 alert alert-danger" role="alert">
                <div>
                  <b>{{previousRejectRevision?.reviewer?.userName}}</b> has rejected the latest revision of this category, please review the reason for rejection and submit a new revision
                </div>
                <div>
                  <b>Rejected Date: </b>
                  {{previousRejectRevision?.reviewedDate | date: 'short'}}
                </div>
                <div class="reject-reason-detail">
                  <b>Reason: </b>
                  <ng-container *ngIf="previousRejectRevision?.description; else noDataDisplay">
                    {{previousRejectRevision?.description}}
                  </ng-container>
                  <ng-template #noDataDisplay>
                    <i>No data display</i>
                  </ng-template>

                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="!isChangePublishRevisionLoading, else publishRevisionLabelLoader">
            <div *ngIf="isChangePublishRevisionAndCategoryDetail, else noChangePublishRevisionAndCategoryDetail" class="w-100 alert alert-warning d-flex flex-row" style="gap: 10px; font-size: 14px; margin-bottom: 0px;">
              <mat-icon (click)="onReloadDetectChange()" style="margin: auto; cursor: pointer">refresh</mat-icon>
              <div class="w-100 d-flex flex-column">
                <span class="fw-600">CHANGES DETECTED</span>
                <span>
                  <span class="fw-600">Last Detected:</span>
                  {{lastDetectedDate | date: 'short'}}
                </span>
              </div>
            </div>
          </ng-container>

          <ng-template #noChangePublishRevisionAndCategoryDetail>
            <div class="w-100 alert alert-dark" style="margin: 0;">
              <div class="d-flex flex-row" style="gap: 10px; width: fit-content; font-size: 14px">
                <mat-icon (click)="onReloadDetectChange()" style="margin: auto; cursor: pointer">refresh</mat-icon>
                <div class="w-100 d-flex flex-column">
                  <span class="fw-600">NO CHANGES DETECTED</span>
                  <span>
                    <span class="fw-600">Last Detected:</span>
                    {{lastDetectedDate | date: 'short'}}
                  </span>
                </div>
              </div>
            </div>
          </ng-template>

          <div *ngIf="inPendingRevision && action == 1 && crmMode != '1'" [nbSpinner]="revisionLoading">
            <div class="w-100 alert alert-primary" role="alert" style="margin-bottom: 0; font-size: 14px; margin-bottom: 8px;">
              This category is being reviewed. If you want to submit a new one, please <a href="javascript:void(0)" (click)="removeRevision()">remove
                this revision from review</a>
            </div>
            <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="12px" class="w-100">
              <button mat-stroked-button *nbIsGranted="['view','reviewer-revision-version']" (click)="approveRevision()">
                <mat-icon style="color: #42d719">check_circle</mat-icon>Approve
              </button>
              <button mat-stroked-button *nbIsGranted="['view','reviewer-revision-version']" (click)="changeStatusRevision(2)">
                <mat-icon style="color: #ee2b2b">cancel</mat-icon>Reject
              </button>
            </div>
          </div>

          <div class="category-table-of-contents altus-card mx-0" fxHide.lt-md>
            <div *ngFor="let item of tableOfContent;  let i = index">
              <button mat-button (click)="scroll(item.value)" class="table-of-content-item" [color]="selectedIndex == item.value ? 'primary' : ''">
                <div fxLayout="row" fxLayoutAlign="space-between start" fxFlexGap="20px">
                  <div>{{item.text}}</div>
                  <div *ngIf="item.value == 'seo' && ffcUrlState">
                    <span class="badge badge-pill badge-primary" [style.background-color]="ffcUrlColor[ffcUrlState]">
                      {{ffcUrlState}}
                    </span>
                  </div>
                  <div *ngIf="item.statusControlName;">
                    <span class="badge badge-pill badge-primary" style="width: 50px" [style.background-color]=" frmCategory.controls[item.statusControlName].value ? 'green' : 'red'">
                      {{ frmCategory.controls[item.statusControlName].value ? 'Active' : 'Inactive'}}
                    </span>
                  </div>
                  <ng-container *nbIsGranted="['view','location-category']">
                    <div *ngIf="item.value == 'location'">
                      <span class="badge badge-pill badge-primary" style="width: 50px" [style.background-color]="location?.frm?.controls['active'].value ? 'green' : 'red'">
                        {{ location?.frm?.controls['active'].value ? 'Active' : 'Inactive'}}
                      </span>
                    </div>
                  </ng-container>
                </div>
              </button>
              <ng-container *ngIf="item.children">
                <div *ngFor="let child of item.children;  let childIdx = index" style="padding-left: 12px">
                  <button mat-button (click)="scroll(child.value)" class="table-of-content-item" [color]="selectedIndex == child.value ? 'primary' : ''">
                    <div fxLayout="row" fxLayoutAlign="space-between start" fxFlexGap="20px">
                      <div>{{child.text}}</div>
                      <div *ngIf="child.statusControlName">
                        <span class="badge badge-pill badge-primary" style="width: 50px" [style.background-color]=" frmCategory.controls[child.statusControlName].value ? 'green' : 'red'">
                          {{ frmCategory.controls[child.statusControlName].value ? 'Active' : 'Inactive'}}
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>

          <ng-container *ngIf="isAIDetectionGranted$; else seoAuditComponentTemplate">
            <mat-tab-group *nbIsGranted="['view', 'request-AI-checker']" #matTabGroup mat-stretch-tabs animationDuration="0ms" class="tab-external-detection">
              <mat-tab #tabSeoAudit>
                <ng-template mat-tab-label>
                  <ng-container *ngIf="!refreshColorExternalTab; else externalTabLabelLoading">
                    <!-- <div [ngClass]="categoryModel?.seoInfo?.overallScore | seoColorScore: '100'"> -->
                    <div>
                      <mat-icon svgIcon="seo-audit"></mat-icon>
                      <span fxHide.lt-sm>{{"SEO Audit" | uppercase}}</span>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-template matTabContent>
                  <ng-container [ngTemplateOutlet]="seoAuditComponentTemplate"></ng-container>
                </ng-template>
              </mat-tab>

              <mat-tab #tabAIDetection>
                <ng-template mat-tab-label>
                  <ng-container *ngIf="!refreshColorExternalTab; else externalTabLabelLoading">
                    <!-- <div class="d-flex justify-content-center align-items-center" [ngStyle]="{'color': categoryModel?.seoInfo?.aiScore | aiDetectionPipe: 'hexColor'}"> -->
                    <div class="d-flex justify-content-center align-items-center">
                      <mat-icon>smart_toy</mat-icon>
                      <span class="ml-1" fxHide.lt-sm>{{'AI Detect' | uppercase}}</span>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-template matTabContent>
                  <ng-container [ngTemplateOutlet]="aiDetectionComponentTemplate"></ng-container>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </ng-container>

          <ng-template #seoAuditComponentTemplate>
            <app-seo-audit *ngIf="crmMode !='1'" class="content-seo-audit" [id]="categoryModel.categoryId" [focusSEOAudit]="focusSEOAuditElement" [focusProp]="focusSEOAudit" [isChange]="isChange">
            </app-seo-audit>
          </ng-template>

          <ng-template #aiDetectionComponentTemplate>
            <app-ai-checker *nbIsGranted="['view', 'request-AI-checker']" class="content-ai-detection" [id]="categoryModel?.categoryId" [type]="'Category'" [focusAIDetect]="focusAICheckElement" (aiScore)="categoryModel?.seoInfo?.aiScore" (orgScore)="categoryModel?.seoInfo?.orgScore" (isFinishDetection)="onAIDetectionChanged($event)" [isChange]="isChange">
            </app-ai-checker>
          </ng-template>
        </div>
      </ng-container>

      <div fxFlex class="content-category" style="background: whitesmoke" #contentCategory>
        <!--Category Information-->
        <div id="category">
          <div class="category-label-section"><b>Category Information</b></div>
          <div class="category-section altus-card" [ngClass]="frmCategory.controls.active?.value ? 'active-section' : ''">

            <div *ngIf="action != 0" class="col-12 d-flex justify-content-center gallery" style="padding-bottom: 1.34375em;">
              <button mat-mini-fab class="stackable" color="primary" (click)="openDialog()" [disabled]="isLockCategory">
                <mat-icon>photo_camera</mat-icon>
              </button>
              <img class="img-fluid normal" style=" object-fit:cover; object-position: center; max-height: 200px;" (click)="showImage($event)" width="100%" [src]="media.media1 ?? 'assets/images/5.jpg' | safeIframe" (error)="media.media1 ='assets/images/error-photo.jpg'">
            </div>

            <div class="col-12">
              <mat-form-field appearance="standard">
                <mat-label>Category Name</mat-label>
                <input matInput formControlName="categoryName" autocomplete="off" required trim="blur">
                <mat-error *ngIf="frmCategory.controls.categoryName.errors && (frmCategory.controls.categoryName.dirty || frmCategory.controls.categoryName.touched)">
                  {{frmCategory.controls.categoryName['errorMessage']}}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12" *ngIf="crmMode !='1'">
              <mat-form-field appearance="standard" [ngClass]="isEditorTopDes ? 'remove-unline' : ''">
                <mat-label>First Description</mat-label>
                <quill-editor *ngIf="isEditorTopDes" class="content-editor " [modules]="editorOptions" formControlName="topDescription">
                </quill-editor>

                <div *ngIf="frmCategory.value.topDescription != null" [innerHTML]="frmCategory.value.topDescription | safeHtml" (click)="changeStatusEditorTopDes()" [hidden]="isEditorTopDes"></div>

                <input matInput formControlName="topDescription" [hidden]="isEditorTopDes || (!isEditorTopDes && frmCategory.value.topDescription != null)" autocomplete="off" (click)="changeStatusEditorTopDes()">
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="standard" [ngClass]="isEditor ? 'remove-unline' : ''">
                <mat-label> {{ crmMode !='1' ? 'Second' : ''}} Description</mat-label>
                <quill-editor *ngIf="isEditor" class="content-editor " [modules]="editorOptions" formControlName="description">
                </quill-editor>

                <div *ngIf="frmCategory.value.description != null" [innerHTML]="frmCategory.value.description | safeHtml" (click)="changeStatusEditor()" [hidden]="isEditor"></div>

                <input matInput formControlName="description" [hidden]="isEditor || (!isEditor && frmCategory.value.description != null)" autocomplete="off" (click)="changeStatusEditor()">
              </mat-form-field>
            </div>
            <div style="display: flex">
              <div class="col-12">
                <app-browse-parent-category [mainCategoryParentId]='frmCategory?.controls?.parentId?.value' [parentCategory]="categoryModel.parent" (getParentId)="getParentId($event)" [isLockCategory]="isLockCategory">
                </app-browse-parent-category>
              </div>
              <!-- <div class="col-3" style="margin-top: 22px;">
                <nb-toggle formControlName="active" status="success">Active</nb-toggle>
              </div> -->
            </div>
            <div class="col-12">
              <!-- <mat-form-field appearance="standard">
                <mat-label>Tags</mat-label>
                <input matInput formControlName="tags" autocomplete="off"  trim="blur">
                <mat-error
                  *ngIf="frmCategory.controls.tags.errors && (frmCategory.controls.tags.dirty || frmCategory.controls.tags.touched)">
                  {{frmCategory.controls.tags['errorMessage']}}</mat-error>
              </mat-form-field> -->
              <mat-form-field appearance="standard" [nbSpinner]="loadingTag">
                <mat-label>Tags</mat-label>
                <mat-chip-list #chipList>
                  <mat-chip *ngFor="let tag of tagsList" style="background-color: #307abd;color: white;" [selectable]="false" [removable]="true" (removed)="remove(tag)" [matTooltip]="tag">
                    {{tag | truncateText: [20,'...']}}
                    <mat-icon matChipRemove *ngIf="!isLockCategory">cancel</mat-icon>
                  </mat-chip>
                  <input placeholder="Select tags..." #tagInput formControlName="tagSelected" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)" class="textarea-inline-control" (keyup.enter)="onClearInput(tagInput.value)" style="min-width: 5.5rem;">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <mat-option *ngFor="let serverTag of tagsObservable | async" [value]="serverTag.tagsName">
                    {{serverTag.tagsName}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!--SEO Information-->
        <div id="seo" *ngIf="crmMode !='1'">
          <div class="category-label-section"> <b>SEO Information</b></div>
          <div class="category-section altus-card">
            <div *ngIf="ffcUrlState || impression != null" class="col-12" style="display: flex; justify-content: flex-end; gap: 8px">
              <span *ngIf="ffcUrlState" class="badge badge-pill badge-primary" style="font-size: 1rem;" [style.background-color]="ffcUrlColor[ffcUrlState]">
                {{ffcUrlState}}
              </span>
              <b *ngIf="impression != null && !isGettingImpression; else impressionLoader" class="badge badge-pill badge-primary" style="font-size: 1rem;" [style.background-color]="impressionColor" style="cursor: pointer; font-size: 1rem;" (click)="openImpressionDialog()">
                Impression: {{impression || '0'}}
              </b>
            </div>
            <div class="col-12" *ngIf="crmMode !='1'">
              <mat-form-field appearance="standard">
                <mat-label>Title</mat-label>
                <input matInput formControlName="title" type="text" trim="blur">
              </mat-form-field>
            </div>
            <div class="col-12" *ngIf="crmMode !='1'">
              <mat-form-field appearance="standard">
                <mat-label>Friendly url</mat-label>
                <input matInput required formControlName="friendlyUrl" type="text" trim="blur">
                <mat-error *ngIf="frmCategory.controls.friendlyUrl.errors && (frmCategory.controls.friendlyUrl.dirty || frmCategory.controls.friendlyUrl.touched)">
                  {{frmCategory.controls.friendlyUrl['errorMessage']}}</mat-error>
                <button mat-icon-button matSuffix style="color:blue" (click)="openSeoLink(frmCategory.controls.friendlyUrl)">
                  <mat-icon>open_in_new</mat-icon>
                </button>
                <button matTooltip="History" mat-icon-button matSuffix (click)="openUrlHistory()">
                  <mat-icon>history</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="col-12" *ngIf="crmMode !='1'">
              <mat-form-field appearance="standard">
                <mat-label>SEO Description</mat-label>
                <textarea matInput cdkTextareaAutosize formControlName="seoDescription" type="text" trim="blur"></textarea>
              </mat-form-field>
            </div>
            <div class="col-12" *ngIf="crmMode !='1'">
              <mat-form-field appearance="standard">
                <mat-label>Keywords (Reference Only)</mat-label>
                <textarea matInput cdkTextareaAutosize formControlName="keywords" trim="blur" [readonly]="isReadonlyKeyword"></textarea>
              </mat-form-field>
            </div>
            <div class="col-12" *ngIf="crmMode !='1'">
              <mat-form-field appearance="standard">
                <mat-label>Primary Keyword (SEO Audit Tool Support)</mat-label>
                <input matInput formControlName="primaryKeyword" trim="blur" type="text" [readonly]="isReadonlyKeyword">
                <mat-error *ngIf="frmCategory?.controls?.primaryKeyword?.errors && frmCategory?.controls?.primaryKeyword?.errors?.maxWordsErrorMessage">
                  {{frmCategory?.controls?.primaryKeyword?.errors?.maxWordsErrorMessage}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12" *ngIf="crmMode !='1'">
              <mat-form-field appearance="standard">
                <mat-label>Secondary Keywords (SEO Audit Tool Support)</mat-label>
                <textarea matInput cdkTextareaAutosize formControlName="secondaryKeyword" trim="blur" [readonly]="isReadonlyKeyword"></textarea>
              </mat-form-field>
            </div>
            <div [formGroup]="frmCategory.controls.media" class="col-12" *ngIf="crmMode !='1' && categoryModel?.media?.mediaId">
              <mat-form-field appearance="standard">
                <mat-label>Primary image alt text</mat-label>
                <textarea matInput cdkTextareaAutosize formControlName="title" trim="blur"></textarea>
              </mat-form-field>
            </div>
            <div class="col-12" *ngIf="media.mediaId">
              <mat-form-field appearance="standard">
                <mat-label>Image Name</mat-label>
                <input matInput formControlName="imageName" autocomplete="off" trim="blur">
                <mat-error *ngIf="frmCategory.controls.imageName.errors && (frmCategory.controls.imageName.dirty || frmCategory.controls.imageName.touched)">
                  {{frmCategory.controls.imageName['errorMessage']}}</mat-error>
              </mat-form-field>
            </div>
            <!-- <mat-divider *ngIf="action != 0" [vertical]="true"></mat-divider> -->
          </div>
        </div>
        <div id="faqs" *ngIf="crmMode !='1'">
          <div class="category-label-section">
            <div style="display: flex">
              <div class="col-9">
                <b>FAQs</b>
              </div>
              <div class="col-3">
                <button mat-button color="primary" (click)="addDetail(true)" [disabled]="isLockCategory">
                  Add Block
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="category-section col-12">
            <form fxLayout="column" fxLayoutAlign="start start">
              <ng-container *ngFor="let detail of (frmCategory?.controls?.faqsList['controls'] || []); let i = index" [ngTemplateOutlet]="blockDetail" [ngTemplateOutletContext]="{
              frmDetail: detail,
              index: i
              }">
              </ng-container>
            </form>
          </div>
        </div>
        <!--Blog Sections-->
        <div id="inspiration" *ngIf="action != 0 && action != 5 && crmMode !='1'">
          <div class="category-label-section"><b>Event Entertainment Inspiration</b></div>
          <div class="category-section">
            <ng-container [ngTemplateOutlet]="sectionInspiration" [ngTemplateOutletContext]="{
            id:'leftBlog',
            label: 'Left Blog',
            link: 'firstHyperLink',
            image: 'firstHyperImage',
            description: 'firstHyperDescription',
            active: 'firstActive'
          }"></ng-container>
            <ng-container [ngTemplateOutlet]="sectionInspiration" [ngTemplateOutletContext]="{
            id: 'rightBlog',
            label: 'Right Blog',
            link: 'secondHyperLink',
            image: 'secondHyperImage',
            description: 'secondHyperDescription',
            active: 'secondActive'
          }"></ng-container>
          </div>
        </div>

        <!-- Ask an expert -->
        <div id="askAnExpert" *ngIf="action != 0 && action != 5 && crmMode !='1'">
          <div class="category-label-section"><b>Ask An Expert</b></div>
          <div class="category-section altus-card" [ngClass]="frmCategory.controls.askAnExpertActive?.value ? 'active-section' : ''">
            <div class="col-12 mb-4">
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <div id="image-section" class="mb-4">
                  <button mat-mini-fab class="stackable" color="primary" (click)="selectMedia('askAnExpertImage')" id="photo-picker" [disabled]="isLockCategory">
                    <mat-icon>photo_camera</mat-icon>
                  </button>
                  <img class="img-fluid normal" style=" object-fit:cover; object-position: center; min-height: 150px;" (click)="showImage($event)" width="150px" [src]="frmCategory.controls.askAnExpertImage?.value?.media1 || 'assets/images/5.jpg'">
                </div>
                <div>
                  <mat-checkbox formControlName="askAnExpertActive" color="primary">Active</mat-checkbox>
                </div>
              </div>
              <label style="opacity: 0.75; font-size: 12px">Description</label>
              <quill-editor class="ask-an-expert-des content-editor" [modules]="editorOptions" style="min-height: 200px" formControlName="askAnExpertDescription"></quill-editor>
            </div>
          </div>
        </div>
        <ng-container *nbIsGranted="['view','location-category']">
          <div *ngIf="action != 0 && action != 5 && categoryModel">
            <div class="category-label-section"> <b>Location</b></div>
            <app-add-edit-category-location #location id="location" [referenceId]="categoryModel.categoryId" [referenceType]="'CATEGORY'" [media]="categoryModel?.media"></app-add-edit-category-location>
          </div>
        </ng-container>

      </div>

    </div>
  </form>

</mat-dialog-content>

<ng-template #uploadImg>
  <mat-dialog-content>
    <mat-form-field class="form-group">
      <ngx-mat-file-input formControlName="rawImage" placeholder="Upload your file" valuePlaceholder="No file selected" required accept="image/png, image/gif, image/jpeg, image/gif" (change)="handleFileInput($event.target.files)">
      </ngx-mat-file-input>
      <mat-icon matSuffix>folder</mat-icon>
      <mat-error *ngIf="!inputForm.get('rawImage').valid">
        Invalid input type(Input must be an image)
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="closeDialogUploadImage()">Cancel</button>
    <button mat-raised-button color="primary" class="float-right" [hidden]="!isGifUpload" (click)="onUpload()">
      <mat-icon fontSet=" material-icons-outlined">upload</mat-icon>Upload
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #sectionInspiration let-label="label" let-img="image" let-link="link" let-desc="description" let-active="active" let-id="id">
  <div *ngIf="!isLoading" class="col-12 mb-4" [formGroup]="frmCategory" class="category-section altus-card" [ngClass]="frmCategory.controls[active]?.value ? 'active-section' : ''" [id]="id">
    <div style="padding-bottom: 24px"><b>{{label}}</b></div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <mat-form-field appearance="standard" class="mr-4">
        <mat-label>Hyper Link {{label}}</mat-label>
        <input matInput type="url" [formControlName]="link" autocomplete="off" trim="blur">

        <mat-error *ngIf="frmCategory.controls[link].errors &&
          (frmCategory.controls[link].dirty || frmCategory.controls[link].touched)">
          {{frmCategory.controls[link]['errorMessage']}}</mat-error>
      </mat-form-field>
      <mat-checkbox fxFlex="80px" class="pt-4" color="primary" [formControlName]="active">Active</mat-checkbox>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <label style="opacity: 0.75; font-size: 12px">Image {{label}}</label>
      <button mat-icon-button (click)="selectMedia(img)" [disabled]="isLockCategory">
        <mat-icon>collections</mat-icon></button>
    </div>
    <div class="box-image mb-4">
      <img width="100%" height="100%" style="object-fit: cover;" [src]="frmCategory?.value[img]?.media1 || 'assets/images/5.jpg'">
      <div class="hid-box" [innerHTML]="(frmCategory.value[desc] || '') | safeHtml"></div>
    </div>

    <label style="opacity: 0.75; font-size: 12px">Description {{label}}</label>
    <input matInput [formControlName]="desc" autocomplete="off" trim="blur" [hidden]="true">
    <quill-editor #descBlog class="content-editor" [modules]="editorBlogs" [formControlName]="desc" (onEditorCreated)="setDesc($event)" (onFocus)="changeFormat($event)">
    </quill-editor>
  </div>
</ng-template>

<ng-template #impressionLoader>
  <div style="width: 80px">
    <ngx-skeleton-loader count="1" animation="progress" [theme]="{ margin: '0' }"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #blockDetail let-frmDetail="frmDetail" let-index="index">
  <div [formGroup]="frmDetail" fxFlexFill class="card shadow-sm my-2 px-3 pb-2" [ngClass]="(index % 2) ? 'bg-info' : 'bg-light'">
    <button class="button-remove-detail" mat-icon-button color="primary" (click)="removeDetail(index)">
      <mat-icon>close</mat-icon>
    </button>
    <mat-form-field appearance="standard">
      <mat-label>Question</mat-label>
      <input matInput formControlName="question" required autocomplete="off" trim="blur">
      <mat-error *ngIf="frmDetail.controls.question.errors && (frmDetail.controls.question.dirty || frmDetail.controls.question.touched)">
        {{frmDetail.controls.question['errorMessage']}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="standard" [ngClass]="isEditorAnswer ? 'remove-unline' : ''">
      <mat-label>Answer</mat-label>
      <quill-editor *ngIf="isEditorAnswer" class="content-editor " [modules]="editorOptions" formControlName="answer">
      </quill-editor>

      <div *ngIf="frmDetail.value.answer != null" [innerHTML]="frmDetail.value.answer | safeHtml" [hidden]="isEditorAnswer"></div>

      <input matInput formControlName="answer" [hidden]="isEditorAnswer || (!isEditorAnswer && frmDetail.value.answer != null)" autocomplete="off">
    </mat-form-field>
  </div>
</ng-template>
<ng-template #publishCheckbox>
  <mat-checkbox class="mt-3" color="primary" [(ngModel)]="isPublishNow">Publish Immediately</mat-checkbox>
</ng-template>

<ng-template #publishRevisionLabelLoader>
  <div class="w-100">
    <ngx-skeleton-loader count="2" animation="progress" [theme]="{ margin: '0' }"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #lockCheckbox>
  <mat-checkbox class="mt-3" color="primary" [(ngModel)]="isCheckLockCategory">Lock this category</mat-checkbox>
</ng-template>
<ng-template #rejectReasonTemplate let-row="row">
  <mat-form-field appearance="outline" class="mt-3" style="width: 400px;">
    <mat-label>Reject Reason</mat-label>
    <textarea matInput cdkTextareaAutosize [(ngModel)]="row.description" placeholder="Reject Reason" cdkAutosizeMinRows="6" cdkAutosizeMaxRows="6"></textarea>
  </mat-form-field>
</ng-template>

<ng-template #externalTabLabelLoading>
  <div style="width: 100%">
    <ngx-skeleton-loader count="1" animation="progress" [theme]="{ margin: '0' }"></ngx-skeleton-loader>
  </div>
</ng-template>