import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OpportunityManagementService } from '../../opportunity-management.service';
import { Helper } from 'src/app/shared/utility/Helper';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { TblActionType } from 'src/app/shared/enums/tbl-action-type.enum';
import { NbToastrService } from '@nebular/theme';
import { DataFieldsManagementService } from 'src/app/shared/services/data-fields-management.service';
import { DataField } from '../../../data-field-management/data-field-model';
import { ProposalRevision, ProposalWhiteLabelData } from '../../opportunity.model';
import { Page } from 'src/app/shared/models/paging/page';
import { PagedData } from 'src/app/shared/models/paging/paged-data';
import { SettingService } from 'src/app/shared/services/setting.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Cmyk, ColorPickerService } from 'ngx-color-picker';
import { QuillConfiguration } from 'src/app/shared/components/stand-alone-component/rich-inline-edit/rich-inline-edit.component';
import { MultiMediaGalleryComponent } from 'src/app/shared/components/multi-media-gallery/multi-media-gallery.component';
import { ProposalArtistPreviewComponent } from './proposal-artist-preview/proposal-artist-preview.component';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { DynamicContentService } from 'src/app/shared/services/dynamic-content.service';
import { DynamicContentModel } from 'src/app/shared/models/dynamic-content.model';
import { Setting } from 'src/app/shared/models/setting.model';

@Component({
  selector: 'app-proposal-artist-template',
  templateUrl: './proposal-artist-template.component.html',
  styleUrls: ['./proposal-artist-template.component.scss']
})
export class ProposalArtistTemplateComponent implements OnInit, AfterViewInit, OnDestroy {
  iframe: ElementRef;
  @ViewChild('iframeTemplate', { static: false }) set iframeContent(content: ElementRef) {
    if (content) {
      this.iframe = content;
      this.setIframe();
    }
  }

  previewProposalComponent: ProposalArtistPreviewComponent;
  @ViewChild('previewProposalComponent', { static: false }) set previewComponent(content: ProposalArtistPreviewComponent) {
    if (content) this.previewProposalComponent = content;
  }

  id: string;
  isPreviewAll: boolean = true;
  sections: SectionModel[] = [];
  sectionCur: string;
  sectionModel: SectionModel;
  templates: TemplateModel[];
  iframeTemplate: string = remindSelectSession;
  loadingTemplate: boolean = false;
  loadingInput: boolean = false;
  dataFields: DataField[] = [];
  revisionCur: ProposalRevision;
  viewRevision: boolean = false;
  page: Page = Object.assign(new Page(), { size: -1 });
  pagedData: PagedData<ProposalRevision>;
  settingURL: string;
  editorOptions = QuillConfiguration
  isModify: boolean = false;
  isMobile: boolean = false;
  bodyLoading: boolean = false;
  initialValue: string;
  messageWarning: string;
  private _destroy: Subject<void> = new Subject<void>();
  nameFiles: DynamicContentModel[] = [];
  isWhiteLabel: boolean = false;
  proposalWhiteLabelData: ProposalWhiteLabelData;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialModalRef: MatDialogRef<ProposalArtistTemplateComponent>,
    private opportunityService: OpportunityManagementService,
    private frmBuilder: RxFormBuilder,
    private toast: NbToastrService,
    private dataFieldService: DataFieldsManagementService,
    private settingService: SettingService,
    private clipboard: Clipboard,
    private cpService: ColorPickerService,
    public matDialog: MatDialog,
    private dynamicContentService: DynamicContentService
  ) {
    this.id = data?.id || data?.model?.proposalId;
    this.isWhiteLabel = data?.isWhiteLabel || false;
    this.revisionCur = data?.model;
    this.settingService.getSettingByKeyAndGroup("PREVIEW_PROPOSAL", "SALE_CONFIG").subscribe(resp => {
      if (resp.result && resp.result.value) this.settingURL = resp.result.value;
    });
  }

  ngOnInit(): void {
    this.dialModalRef.updateSize('80vw', '100vh');
    this.dialModalRef.updatePosition({ right: '0', bottom: '0' });
  }

  ngAfterViewInit(): void {
    this.setupProposalTemplate();
    this.setIframe();
  }

  ngOnDestroy(): void {
    if (this._destroy) {
      this._destroy.next();
      this._destroy.complete();
    }
  }

  async setupProposalTemplate() {
    this.loadingTemplate = true;
    var resNameFiles = await this.dynamicContentService.getDynamicContentByType("name_proposal_template").toPromise();
    this.nameFiles = resNameFiles?.result || [];

    var resTemplate = await this.opportunityService.proposalTemplate().toPromise();
    var resDataField = await this.dataFieldService.getDataFieldByScreen("ProposalArtist").toPromise();
    var whiteLabelHeaderFileName: Setting = null;
    var whiteLabelFooterFileName: Setting = null;
    if (this.isWhiteLabel) {
      whiteLabelHeaderFileName = (await this.settingService.getSettingByKeyAndGroup('WHITE_LABEL_HEADER_NAME', 'INTERACTIVE_PROPOSAL').toPromise())?.result;
      whiteLabelFooterFileName = (await this.settingService.getSettingByKeyAndGroup('WHITE_LABEL_FOOTER_NAME', 'INTERACTIVE_PROPOSAL').toPromise())?.result;
    }

    if (!this.revisionCur) {
      var resProposal = await this.opportunityService.getProposalRevisionById(this.id).toPromise();
      if (resProposal.result) this.revisionCur = resProposal.result;
    }
    var content: {} = this.revisionCur?.content ? JSON.parse(this.revisionCur.content) : null;
    if (resDataField.result) this.dataFields = resDataField.result || [];
    if (resTemplate.result) {
      if (!this.templates) this.templates = [];
      for (const [key, value] of Object.entries(resTemplate.result)) {
        if (value) {
          let selectedTemplate = Array.from(value)?.find((value, i) => i == 0)?.toString();
          if (content && content['sections'])
            selectedTemplate = content['sections'][key];

          if(whiteLabelHeaderFileName?.value && key === 'header') {
            var listHeaderFileName = whiteLabelHeaderFileName?.value?.split(',');
            var index = value.findIndex(x => listHeaderFileName?.includes(x));
            if(index > -1) selectedTemplate = value[index];
          }

          if(whiteLabelFooterFileName?.value && key === 'footer') {
            var listFooterFileName = whiteLabelFooterFileName?.value?.split(',');
            var index = value.findIndex(x => listFooterFileName?.includes(x));
            if(index > -1) selectedTemplate = value[index];
          }
          
          let sectionTemplate: TemplateModel = Object.assign({}, {
            key: key,
            selected: selectedTemplate,
            templates: Array.from(value)?.map(x => ({ fileName: x, contentHTML: null } as SectionTemplate)) || []
          } as TemplateModel);

          this.templates.push(sectionTemplate);
        }
      }

      if (content) {
        delete content['sections'];
        this.bindingDataRegex(content);
      } else this.regexMergeTagProposal(this.templates, true);
    }
    await this.getWhiteLabelURLData();
    this.loadingTemplate = false;
  }

  async regexMergeTagProposal(sections: TemplateModel[] = [], isCreate: boolean = false, bodies: TemplateModel[] = [], allowOverwriteData: string = null) {
    this.loadingInput = true;
    var resp = await this.opportunityService.setUpArtistProposal(this.id, sections, bodies).toPromise();
    if (resp.result) {
      this.bindingDataRegex(resp.result, false, allowOverwriteData);
      if (isCreate) this.generateProposal();
    }

    this.loadingInput = false
  }

  bindingDataRegex(jsonData: any, cleanCache: boolean = false, overwriteData: string = null) {
    if (jsonData) {
      this.messageWarning = "";
      if (!this.sections) this.sections = [];

      //Filter section current existing with new section.
      if (this.sections && this.sections.length > 0)
        this.sections = [...this.sections.filter(x => Object.keys(jsonData).includes(x.key))];

      //Binding input section
      for (const [key, value] of Object.entries(jsonData)) {
        //Valid exited
        let existed = this.sections.find(x => x.key == key);

        const dataSection: SectionModel = this.convertProposalDataToForm(key, value, cleanCache, overwriteData);
        if (key && existed) this.sections.splice(this.sections.indexOf(existed), 1, dataSection);
        if (key && !existed) this.sections.push(dataSection);
        this.sections = [...this.sections];
      }

      if (this.sectionCur) this.sectionModel = this.sections.find(x => x.key == this.sectionCur);
    }
  }

  convertProposalDataToForm(key, value, cleanCache: boolean = false, overwriteData: string = null): SectionModel {
    this.validateStructureProposal(key, value);
    let valueName = value.hasOwnProperty("[ARTIST_TITLE]") ? value["[ARTIST_TITLE]"] : '';
    let fields: string[] = Object.keys(value);
    let frmModel = value;
    let order = value["ORDER"] || 0;
    let fieldType: string[] = fields && fields.length > 0
      ? fields.map(x => {
        var type: string = EnumTypeInput[EnumTypeInput.TEXT];
        if (this.dataFields) {
          var findType = this.dataFields.find(y => x == y.dataFieldKey || x.includes(y.dataFieldKey));
          if (findType) type = findType.dataFieldType;

          switch (type) {
            case EnumTypeInput[EnumTypeInput.COLOR_CODE]:
              frmModel[x] = frmModel[x] || findType?.displayData || '#003066';
              break;
            case EnumTypeInput[EnumTypeInput.LINK]:
              frmModel[x] = frmModel[x] || findType?.displayData || 'https://altusentertainment.com';
              break;
          }
        }
        return type;
      }) : [];


    //Existed key in this.sections.
    let existed = this.sections.find(x => x.key == key);
    if (existed != null && !cleanCache) {
      let copyFrmValue = existed.form?.value || {};

      //Binding data input from user.
      if (copyFrmValue)
        for (const [key, value] of Object.entries(copyFrmValue)) {
          if (!frmModel.hasOwnProperty(key))
            delete copyFrmValue[key];
        }

      // Keep the old value
      if (!overwriteData || overwriteData === EnumRegenerateOption.KEEP_OLD_DATA.toString()) {
        frmModel = Object.assign({}, frmModel, copyFrmValue);
      }
      // Rewrite all the value
      else {
        frmModel = Object.assign({}, copyFrmValue, frmModel);
      }
    }

    let form = fields && fields.length > 0 ? this.frmBuilder.group(frmModel) : null;
    if (form) form.valueChanges.pipe(takeUntil(this._destroy))
      .subscribe(x => this.isModify = true);
    const dataSection = { key: key, value: valueName || key?.toUpperCase(), form: form, fields: fields, fieldType: fieldType, order: order } as SectionModel;
    return dataSection;
  }

  validateStructureProposal(key: string, value: any) {
    if (!value) this.messageWarning = messageErrStructure;
    if (key && value) {
      switch (key) {
        case 'header':
        case 'proposal':
        case 'footer':
          if (!value.hasOwnProperty("ORDER"))
            this.messageWarning = messageNewStructure;
          break;
        default:
          if (!value.hasOwnProperty("BODY") || !value.hasOwnProperty("ORDER"))
            this.messageWarning = messageNewStructure;
          break;
      }
    }
  }

  async selectionSection(event: MatSelectChange) {
    if (event) {
      let sectionSelection = "";
      switch (event.value) {
        case "proposal": break;
        case "header":
        case "footer":
          sectionSelection = event.value;
          break;
        default:
          sectionSelection = event.value;
          break;
      }

      this.sectionModel = this.sections.find(x => x.key == event.value);
      this.getContentHTML(sectionSelection);
    }
  }

  generateProposal() {
    if (this.sections && this.sections.length > 0) {
      var model = {};
      for (var item of this.sections) {
        var object = Object.assign({}, { [item.key]: item?.form?.value || {}, })
        model = Object.assign({}, model, object);
      }
      this.opportunityService.generateArtistProposal(this.id, model, this.templates)
        .subscribe(resp => {
          if (resp.result) {
            this.isModify = false;
            this.toast.success("Publish successfully!", "Success");
            this.revisionCur = resp.result;
            if (this.viewRevision) this.loadRevisionPage();
          }
        })
    }
  }

  async getContentHTML(sectionSelection: string) {
    if (sectionSelection && this.sections && this.sections.length > 0) {
      this.loadingIframe(true);
      let fileName: string;
      if (sectionSelection.length >= 36) {
        sectionSelection = "body";
        fileName = this.sectionModel.form.value['BODY'];
      }
      let sectionTemplate = this.templates.find(x => x.key == sectionSelection);
      var model = {};
      if (this.sectionCur) {
        var findSection = this.sections.find(x => x.key == this.sectionCur);
        if (findSection) {
          var object = Object.assign({}, { [findSection.key]: findSection?.form?.value || {}, })
          model = Object.assign({}, model, object);
        }
      }

      if (sectionTemplate && sectionTemplate.templates.length > 0) {
        if (!fileName) {
          fileName = sectionTemplate.selected
        }
        let file = sectionTemplate.templates.find(x => x.fileName == fileName);

        if ((file && file.fileName && !file.contentHTML) || model) {
          sectionTemplate.selected = fileName;
          var respFile = await this.opportunityService.contentSectionTemplate([sectionTemplate], model).toPromise();
          if (respFile.result) file.contentHTML = respFile.result;
        }

        if (file && file.contentHTML) this.iframeTemplate = file.contentHTML;
      }

      this.loadingIframe(false);
      if (!this.iframeTemplate) this.iframeTemplate = errLoadTemplate;
    }

    this.setIframe();
  }

  loadingIframe(loading: boolean = false) {
    if (this.iframe) {
      var loadingHTML: string = loadingIframe;
      this.iframeTemplate = loading
        ? loadingIframe
        : this.iframeTemplate == loadingHTML
          ? "" : this.iframeTemplate;

      this.setIframe();
    }
  }

  setIframe() {
    if (this.iframe) {
      let doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
      doc.open();
      doc.write(this.iframeTemplate);
      doc.close();
    }
  }

  async selectTemplate(key: string) {
    if (key) {
      var template = this.templates.find(x => x.key == key);
      if (template) {
        this.isModify = true;
        var bodies: TemplateModel[] = this.sections
          ?.filter(x => x.fields.includes("BODY"))
          ?.map(x => ({ key: x.key, selected: x.form?.value?.BODY } as TemplateModel)) || [];

        await this.regexMergeTagProposal(this.templates, false, bodies);

        var section: string = '';
        if (template.key == this.sectionCur) section = template.key;
        if (!section && this.sectionCur && this.sectionCur.length == 36)
          section = "body";

        if (section) this.getContentHTML(section);
      }
    }
  }

  switchSeenRevision() {
    this.viewRevision = !this.viewRevision;
    var width: string = this.viewRevision ? '95vw' : '80vw';

    if (this.dialModalRef) this.dialModalRef.updateSize(width, '100vh');
    if (!this.pagedData && this.viewRevision) this.loadRevisionPage()
  }

  loadRevisionPage() {
    this.pagedData = null;
    this.opportunityService.getProposalRevisionPaging(this.page, this.id)
      .subscribe({
        next: resp => {
          if (resp.result) {
            this.pagedData = resp.result;
            this.pagedData.data = this.pagedData.data.map(x => ({
              ...x,
              userName: (`${x.user.firstName || ''} ${x.user.lastName || ''}`).trim()
            }))
          }
        },
        error: err => this.pagedData = new PagedData<ProposalRevision>(),
      });
  }

  restoreRevision(data: ProposalRevision) {
    if (data) {
      this.revisionCur = data;
      var content: {} = this.revisionCur?.content ? JSON.parse(this.revisionCur.content) : null;

      if (this.templates && this.templates.length > 0) {
        for (let section of this.templates) {
          if (content && content['sections'])
            section.selected = content['sections'][section.key];
        }
      }

      if (content) {
        delete content['sections'];
        this.bindingDataRegex(content, true);
        if (this.sectionModel) this.getContentHTML(this.sectionCur);
        else {
          this.iframeTemplate = remindSelectSession;
          this.setIframe();
        }

        this.toast.success("Restore successfully!", "Success");
      }
    }
  }

  openLink(data: ProposalRevision) {
    if (!this.settingURL) this.toast.warning("Please setup URL in setting", "Warning");
    if (this.settingURL) window.open(`${this.settingURL}/${data.id}.html`, '_blank')
  }

  copyLink(data: ProposalRevision) {
    if (!this.settingURL) this.toast.warning("Please setup url in setting", "Warning");
    if (this.settingURL) {
      this.clipboard.copy(`${this.settingURL}/${data.id}.html`);
      this.toast.info(`Copied url success`, 'Success')
    }
  }

  onChangeColor(color: string): Cmyk {
    const hsva = this.cpService.stringToHsva(color);
    const rgba = this.cpService.hsvaToRgba(hsva);
    return this.cpService.rgbaToCmyk(rgba);
  }

  async selectMedia(fromControl, typeMediaType) {
    const dialog = this.matDialog.open(MultiMediaGalleryComponent, {
      autoFocus: false,
      data: {
        profileId: this.sectionModel.key,
        typeMediaType,
        selectedItem: this.sectionModel.form.controls[fromControl]?.value,
        displayName: this.sectionModel.form.controls['[ARTIST_TITLE]']?.value
      }
    });

    const resultAfterClose = await dialog.afterClosed().toPromise();
    if (resultAfterClose) {
      let mediaUrl = resultAfterClose.media1
      if(typeMediaType == 2 && this.isWhiteLabel) {
        if(mediaUrl.includes("spotlightr")) {
          try {
            const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/;
            const match = mediaUrl.match(regex);
            const rootDomain = match[1];
            const whiteLabelUrl = `videos.${this.proposalWhiteLabelData.rootWhiteLabelUrl}`;
            mediaUrl = mediaUrl.replace(rootDomain, whiteLabelUrl);
          }
          catch(ex) {
            console.error(ex);
          }
        }
      }
      this.sectionModel.form.controls[fromControl].patchValue(mediaUrl);
      this.detectModify(mediaUrl);
    }
  }

  renewProposal(allowOverwriteData: string) {
    if (this.templates && this.templates.length > 0)
      this.regexMergeTagProposal(this.templates, true, null, allowOverwriteData);
  }
  async selectArtistTemplate(event: MatSelectChange) {
    this.bodyLoading = true;
    const result = await this.opportunityService.getBodyTemplateById(this.sectionCur, this.sectionModel.form.value).toPromise();
    if (result.result) {
      let newTemplate = {};
      const findSection = this.sections.findIndex(x => { return x.key == this.sectionCur });
      const dataSection = this.convertProposalDataToForm(this.sectionCur, result.result);
      this.sections[findSection] = dataSection;
      if (this.sectionCur) this.sectionModel = this.sections.find(x => x.key == this.sectionCur);
      await this.getContentHTML(this.sectionCur);

    }
    this.bodyLoading = false;
  }


  closeDialog() {
    if (!this.isModify) this.dialModalRef.close();
    else {
      const confirmClose = this.matDialog.open(ConfirmModalComponent, {
        data: { message: 'Do you wish to close this popup? You will lose your unsaved data.' },
      });

      confirmClose.afterClosed().pipe(takeUntil(this._destroy))
        .subscribe(resp => {
          if (resp) this.dialModalRef.close();
        })
    }
  }

  public isValidGUID(value: string): boolean {
    if (value.length > 0) {
      if (!(/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/).test(value)) {
        return false;
      }
    }

    return true;
  }

  initialValueFunc(value) {
    this.initialValue = value;
  }

  editorInit(quill: any) {
    quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
      delta?.forEach(e => {
        try {
          if (e?.attributes) {
            e.attributes.color = '';
            e.attributes.background = '';
          }
        }
        catch (error) {
          console.error(error)
        }
      });
      return delta;
    });
  }

  detectModify(value) {
    if (value !== this.initialValue)
      this.getContentHTML(this.sectionCur);
  }
  async getWhiteLabelURLData() {
    try {
      const result = await this.opportunityService.getProposalWhiteLabelData(this.id).toPromise();
      if(result.result) {
        this.proposalWhiteLabelData = result.result;
      }
    }
    catch(ex) {

    }
  }
}

export const EnumRegenerateOption = {
  KEEP_OLD_DATA: 'KEEP_OLD_DATA',
  UPDATE_ALL_DATA: 'UPDATE_ALL_DATA'
}
export class SectionModel {
  key: string;
  value: string;
  form: FormGroup;
  fields: string[];
  fieldType: string[];
  order: number;
}

export class TemplateModel {
  key: string;
  selected: string;
  templates: SectionTemplate[];
}

export class SectionTemplate {
  fileName: string;
  contentHTML: string;
}

export enum EnumTypeInput {
  TEXT,
  TEXT_AREA,
  LINK,
  COLOR_CODE,
  IMAGE_PICKER,
  VIDEO_PICKER,
  RICH_TEXT,
  HIDDEN
}

const messageErrStructure = `The structure proposal invalid, please regenerate proposal!`;
const messageNewStructure = `System has new structure proposal, please regenerate proposal!`;

export const remindSelectSession = `
<h1 style="opacity: 0.75; text-align: center; padding-top: 45%;">
  Kindly indicate the specific proposal section you would like to modify.
<h1>
`;

export const errLoadTemplate = `<h1 style="opacity: 0.75; text-align: center; padding-top: 45%; color: red;">Can not load template.<h1>`;
export const loadingIframe = `<!DOCTYPE html>
<html>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1">
<style>
body {
  height: calc(100vh - 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
</head>
<body>
	<div class="loader"></div>
</body>
</html>
`;
