<!-- <div appChangeUrlObjects [id]="id" [isOpenDialog]="openByDialog" [topic]="topic" [name]="saleLeadModel?.displayName"></div> -->
<mat-chip-list></mat-chip-list>
<ng-container *ngIf="isShowButton">
    <div class="d-flex flex-wrap align-items-space-between" mat-dialog-title [nbSpinner]="isLoadingEdit"
        style="margin: unset;padding: 8px 10px 1px 28px;">
        <div [hidden]="isLoading" class="d-flex flex-wrap" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <mat-icon style="transform: scale(1.5);" [ngStyle]="{'color':colorCode.SaleLead}">
                    account_circle</mat-icon>
            </div>
            <div class="entity-name-id">
                <span class="entity-name" fxLayout="row" fxLayoutAlign="space-around center">
                    {{environment.titleLead}}
                </span>
                <span *ngIf="!isCopy;else copied"
                    [matTooltip]="'Click to copy this ' + environment.titleLead + ' ID: ' + id"
                    (click)="copyGUIDToClipboard()" class="underline-id">#{{id | truncateText:[6,
                    '...']}}</span>
            </div>
            <mat-divider [vertical]="true">
            </mat-divider>
        </div>


        <!-- Last Contact -->
        <div fxFlex="350px" class="mx-1" *nbIsGranted="['view', 'show-last-contact']">
            <div fxLayoutAlign="center center" class="w-100" *ngIf="lastContact != null && lastContact != ''"
                style="height: 40px" [style.background-color]="lastContact | getColorLastContact">
                <span class="mt-1" style="font-size: 16px">
                    {{lastContact != "Never Contacted" ? 'Last Contact: ' : ''}}
                    {{lastContact != "Never Contacted" ? (lastContact | date: 'MM/dd/YYYY') : ''}}
                    {{lastContact != "Never Contacted" ? '(' + (lastContact | subtractDate) + ')' : lastContact}}
                </span>
            </div>
        </div>
        <!-- End -->

        <div class="ml-auto" fxLayout="row">
            <div fxLayout="row" fxLayoutAlign="start center">
                <span class="button-group">
                    <button mat-icon-button class="ml-auto float-start" matTooltipPosition="left"
                        [matTooltip]="'Copy this ' + environment.titleLead + ' url to clipboard'"
                        (click)="copyToClipboard()">
                        <mat-icon style="transform: scale(0.9);">share
                        </mat-icon>
                    </button>
                    <button mat-icon-button class="ml-auto float-start" matTooltipPosition="left"
                        [matTooltip]="'Convert this ' + environment.titleLead" (click)="openConvertSaleLeadModal()">
                        <mat-icon style="transform: scale(0.9);" style="color:green">swap_horiz
                        </mat-icon>
                    </button>
                </span>
                <mat-divider [vertical]="true" style="height:100%">
                </mat-divider>
            </div>
            <app-next-or-previous-detail #btnNextOrPre>
            </app-next-or-previous-detail>
            <button *ngIf="openByDialog" mat-icon-button matTooltip="Close" (click)="closeDialog()">
                <mat-icon style="color: red">close</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
</ng-container>

<mat-dialog-content style="max-height: 100vh" *ngIf="isLoading; then skeleton else bodyDialog"></mat-dialog-content>

<!--#region ng-template-->
<ng-template #bodyDialog>
    <div *ngIf="saleLeadModel; else notExistedById" appDetectTabMode [typeElement]="'BodyDetail'"
        class="body-detail-container px-4" [ngClass]="!openByDialog ? 'embed-parent' : ''">
        <div fxLayout="row" fxLayoutAlign="start start">
            <div *ngIf="data && data.advancedImport" style="width:224px;">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px"
                    style="width:200px;padding-right: 24px;" *ngIf="!data.externalTemplate; else externalTemplate">
                    <button mat-menu-item (click)="onClickAdvanceImport('Add')" [matTooltip]="'Create new entry'">
                        <mat-icon style="color:#28a745">add</mat-icon>
                    </button>
                    <button mat-menu-item (click)="onClickAdvanceImport('Update')"
                        [matTooltip]="'Update existing entry'">
                        <mat-icon color="primary">edit</mat-icon>
                    </button>
                    <button mat-menu-item (click)="onClickAdvanceImport('Skip')" [matTooltip]="'Skip Entry'">
                        <mat-icon style="color: red;">close</mat-icon>
                    </button>
                </div>
                <div style="overflow:auto; height: 80vh;width:200px;">
                    <div *ngFor="let item of data.importColumn" style=" margin-right: 24px;">
                        <div class="import-advanced-label">
                            {{item.name}}:
                        </div>
                        <div [matTooltip]="item.value" style="white-space: nowrap;">
                            {{item.value || 'No data display'}}
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </div>

            </div>
            <div class="content-container">
                <div fxLayout="row wrap" fxLayoutAlign="start end" class="mt-3">
                    <ng-container [ngTemplateOutlet]="contactNameProperty" [ngTemplateOutletContext]="{
                icon: 'account_circle',
                saluation: saleLeadModel.saleLeadsContact.saluation,
                firstName: saleLeadModel.saleLeadsContact.contactName,
                lastName: saleLeadModel.saleLeadsContact.contactLastName,
                title: saleLeadModel.profileAdditionDetail?.title
            }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="accountOwner" [ngTemplateOutletContext]="{}"></ng-container>
                    <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'apartment', toolTip: 'Company',
                property: 'company1',
                value: saleLeadModel.company1,
                typeModel: TypeEditModelSaleLead.SaleLead,
                observable :dynamicContentCompanyAPI,
                searchProp: 'content',
                flexDefault: 24,
                flexSm: 48
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'phone', toolTip: 'Phone Number',
                property: 'contactPhone',
                value: saleLeadModel.saleLeadsContact.contactPhone | phoneFormat,
                typeModel: TypeEditModelSaleLead.Contact,
                observable :dynamicContentPhoneNumber,
                searchProp: 'content',
                flexDefault: 24,
                flexSm: 48,
                suffixFunc: resetContacts
            }"></ng-container>
                    <ng-container *nbIsGranted="['view', 'contact-phone-extension']"
                        [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
                icon: 'info', toolTip: 'Phone Ext',
                property: 'primaryPhoneExt',
                value: saleLeadModel.saleLeadsContact.primaryPhoneExt,
                typeModel: TypeEditModelSaleLead.Contact,
                flexDefault: 24,
                flexSm: 48,
                suffixFunc: resetContacts,
                pipe: phoneExtPipe,
                validProp: validProperties.phoneExt
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'alternate_email', toolTip: 'Email',
                property: 'contactEmail',
                value: saleLeadModel.saleLeadsContact.contactEmail,
                typeModel: TypeEditModelSaleLead.Contact,
                validProp: validProperties.contactEmail,
                observable :dynamicContentEmail,
                searchProp: 'content',
                flexDefault: 24,
                flexSm: 48,
                suffixFunc: resetContacts,
                isAscii: true
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'language', toolTip: 'Website',
                property: 'webPage',
                value: saleLeadModel.webPage,
                typeModel: TypeEditModelSaleLead.SaleLead,
                isAccess: true,
                observable :dynamicContentWebsite,
                searchProp: 'content',
                flexDefault: 24,
                flexSm: 48
            }"></ng-container>
                    <ng-container *ngIf="isCRMMode == '1'" [ngTemplateOutlet]="autocompleteInline"
                        [ngTemplateOutletContext]="{
                icon: 'groups', toolTip: 'Company Size',
                property: 'companySize',
                value: saleLeadModel.profileAdditionDetail?.companySize,
                typeModel: TypeEditModelSaleLead.SaleLead,
                observable: undefined,
                searchProp: 'content',
                flexDefault: 24,
                flexSm: 48,
                addition: true
                }"></ng-container>
                    <!-- <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
                icon: 'paid', toolTip: 'Annual Revenue',
                property: 'annualRevenue',
                value: saleLeadModel.annualRevenue,
                typeModel: TypeEditModelSaleLead.SaleLead,
                validProp: validProperties.annualRevenue,
                pipe: numberPipe,
                prefix: prefixCurrency
            }"></ng-container>
                    <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                icon: 'apartment', toolTip: 'Lead source',
                property: 'leadSource',
                value: saleLeadModel.leadSource,
                typeModel: TypeEditModelSaleLead.SaleLead,
                observable :dynamicContentLeadSourceAPI,
                searchProp: 'content',
                flexDefault: 24,
                flexSm: 48
            }"></ng-container> -->
                    <ng-container [ngTemplateOutlet]="areaDescriptionNote"></ng-container>
                </div>

                <div class="mt-2" style="padding-bottom: 9px;">
                    <app-chevron-progress-bar [chevronProgressBarLst]="stageLst" [displayText]="'dataStateName'"
                        [displayValue]="'automateDataStateId'" [selectedValue]="saleLeadModel.automateDataStateId"
                        (onSelected)="editStage($event)"></app-chevron-progress-bar>
                </div>
                <mat-tab-group #tabGroup mat-stretch-tabs animationDuration="0ms" [selectedIndex]="selectTab"
                    (selectedIndexChange)="tabChanged($event)">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            Address Information
                            <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                                (throttleClick)="openLocationDialog()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <!-- <app-sale-lead-location-tab [id]="id" [locations]="saleLeadModel.profileLocations">
                    </app-sale-lead-location-tab> -->
                            <app-location-grid #locationGrid [relationshipId]="id" [relationshipType]="'Profile'"
                                (refreshParentData)="refreshData()">
                            </app-location-grid>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Extend Data">
                        <ng-template mat-tab-label>
                            Extend Data
                            <app-add-extend-data-button [id]="id" [extendData]="saleLeadModel?.extendData"
                                (onRefresh)="refreshData()">
                            </app-add-extend-data-button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-sale-lead-additional-tab [id]="id" [model]="saleLeadModel"
                                (onRefresh)="refreshData(); resetContacts()">
                            </app-sale-lead-additional-tab>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Files" *nbIsGranted="['view', 'table-sale-lead-file']">
                        <ng-template mat-tab-label>
                            Files
                            <button *nbIsGranted="['view', 'add-profile-file']" #nbFilesBtn="nbPopover" mat-icon-button
                                color="primary" debouncedClick [throttleTime]="500" (throttleClick)="nbPopoverCLick()"
                                [nbPopover]="addFilesMenu" nbPopoverPlacement="left">
                                <mat-icon>add</mat-icon>
                            </button>
                            <!-- <button *nbIsGranted="['view', 'add-sale-lead-file']" mat-icon-button color="primary"
                                debouncedClick [throttleTime]="500" (throttleClick)="fileInput.click()">
                                <mat-icon>add</mat-icon>
                            </button> -->
                            <button mat-icon-button *nbIsGranted="['view', 'open-folder-365']"
                                style="color: rgb(5, 56, 5)" debouncedClick [throttleTime]="500"
                                (throttleClick)="openOneDriveFolderUrl()" [nbPopover]="addFilesMenu"
                                nbPopoverPlacement="left">
                                <mat-icon>cloud_queue</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-profile-file-tab #profileFileTab [profileModel]="saleLeadModel" [perm]="fileResource">
                            </app-profile-file-tab>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Note" *nbIsGranted="['view', 'table-note-sale-lead']">
                        <ng-template mat-tab-label>
                            Note
                            <button mat-icon-button color="primary" debouncedClick [throttleTime]="500" (throttleClick)="noteService.pushNoteDetails({
                            referenceId: id,
                            referenceType: 'LEADS',
                            referenceName: saleLeadModel.displayName
                        })">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-note-tab #gridNoteTab [id]="id" [referenceType]="'LEADS'" [resource]="resource"
                                (editChange)="noteService.pushNoteDetails($event.row, saleLeadModel.displayName)"
                                (deletedChange)="overlayNoteDetails ? overlayNoteDetails.deleteNoteFromOutSide($event) : '';
                        getLastNote($event, true)" [nbSpinner]="overlayNoteDetails?.isLoading | async">
                            </app-note-tab>
                        </ng-template>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            Tasks ({{saleLeadModel?.profileAdditionDetail?.totalTask || 0}})
                            <button mat-icon-button color="primary" debouncedClick [throttleTime]="500"
                                (throttleClick)="createTask()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-task-grid-by-profile-id #taskGrid [profileId]="id" [saleModel]="saleLeadModel"
                                (refreshDataValue)="onRefresh($event)" [classTable]="''"></app-task-grid-by-profile-id>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label=" Activity Log">
                        <ng-template mat-tab-label>
                            Activity Log
                            <button mat-icon-button color="primary" appAddActivityLogBtn [id]="id" type="LEADS" (onRefresh)="refreshLastContact()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-sale-lead-activity-tab [id]="id" [model]="saleLeadModel"></app-sale-lead-activity-tab>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #notExistedById>
    <app-blank-page [message]="'The ' + environment.titleLead + ' with id ' + id +  ' is not exist or deleted'">
    </app-blank-page>
</ng-template>

<ng-template #skeleton>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-3 px-4">
        <ngx-skeleton-loader fxFlex="48" count="5" animation="progress"></ngx-skeleton-loader>
        <ngx-skeleton-loader fxFlex="48" count="5" animation="progress"></ngx-skeleton-loader>
    </div>
    <div fxLayout="column" class="px-4">
        <ngx-skeleton-loader fxFlex="100" count="3" animation="progress"></ngx-skeleton-loader>
    </div>
    <mat-divider class="mt-2 mx-4"></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-3 px-4">
        <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
        <mat-divider style="margin-top: -1rem; height: 44px;" [vertical]="true"></mat-divider>
        <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
        <mat-divider style="margin-top: -1rem; height: 44px;" [vertical]="true"></mat-divider>
        <ngx-skeleton-loader fxFlex="32" count="1" animation="progress"></ngx-skeleton-loader>
    </div>
    <mat-divider class="mx-4"></mat-divider>
    <div fxLayout="column" class="mt-3 px-4">
        <ngx-skeleton-loader fxFlex="100" [theme]="{
            height: '50vh'
        }" animation="progress"></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #defaultProperty let-icon="icon" let-toolTip="toolTip" let-property="property" let-value="value"
    let-typeModel="typeModel" let-validProp="validProp" let-isAccess="isAccess" let-pipe="pipe" let-prefix="prefix"
    let-fxFlex="fxFlex" let-externalIcon="externalIcon">
    <div [fxFlex]="fxFlex || 24" fxFlex.lt-sm="48" fxLayout="row wrap" fxLayoutAlign="flex-start center"
        class="mb-2 pr-2" [matTooltip]="toolTip" matTooltipPosition="left">
        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
            {{toolTip}}
        </span>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
            <span *ngIf="!externalIcon" fxFlex="30px" class="material-icons-outlined blue-color" color="primary"
                style="font-size: 24px;">
                {{icon}}
            </span>
            <span *ngIf="externalIcon" fxFlex="30px" class="material-icons-outlined blue-color" color="primary"
                style="font-size: 24px;">
                <i [class]="'fab fa-' + icon + ' ' + 'blue-color'"></i>
            </span>
            <app-inline-edit style="width: calc(100% - 30px)" [inputData]="value"
                (handleSave)="editAccountContact($event, property, typeModel)" [removePaddingBottom]="true"
                [widthDisplay]="'100%'" [validatorValue]="validProp ? validProp.valid : null"
                [messageError]="validProp ? validProp.message : null" [usingMessageParent]="true" [enterToSubmit]="true"
                [escapeToCancel]="true" [isAccess]="isAccess ?? false" [pipe]="pipe" [prefix]="prefix">
            </app-inline-edit>
        </div>
    </div>
</ng-template>

<ng-template #contactNameProperty let-icon="icon" let-saluation="saluation" let-firstName="firstName"
    let-lastName="lastName" let-title="title">
    <div fxFlex="48" fxFlex.lt-sm="100" fxLayout="row wrap" fxLayoutAlign="flex-start center"
        class="main-title mb-2 pr-2">
        <div fxFlex="100px" fxLayout="column">
            <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
                Salutation
            </span>
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
                <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary" style="font-size: 24px;">
                    {{icon}}
                </span>
                <mat-select [value]="saluation"
                    (selectionChange)="editAccountContact($event.value, 'saluation', TypeEditModelSaleLead.Contact); resetContacts()"
                    class="select-saluation mr-1" style="width: 60px" placeholder="None" matTooltip="Saluation"
                    matTooltipPosition="left">
                    <mat-option *ngFor="let val of saluationList" [value]="val">
                        <span class="badge badge-pill badge-light" style="font-size: 13px; background-color: white;">
                            {{val}}
                        </span>
                    </mat-option>
                </mat-select>
            </div>
        </div>

        <div style="max-width: calc((100% - 110px)/3);" fxLayout="column" class="mr-2">
            <span fxFlex="100" class="limit-line label-inline" style="--line: 1; padding: 0">
                First Name
            </span>
            <app-inline-edit class="mr-1" [inputData]="firstName"
                (handleSave)="editAccountContact($event, 'contactName', TypeEditModelSaleLead.Contact); resetContacts(); refreshTask.emit()"
                [usingMessageParent]="true" [removePaddingBottom]="true" [widthDisplay]="'100%'" matTooltip="First name"
                matTooltipPosition="left" [enterToSubmit]="true" [escapeToCancel]="true">
            </app-inline-edit>
        </div>

        <div style="width: calc((100% - 110px)/3)" fxLayout="column">
            <span fxFlex="100" class="limit-line label-inline" style="--line: 1; padding: 0">
                Last Name
            </span>
            <app-inline-edit [inputData]="lastName"
                (handleSave)="editAccountContact($event, 'contactLastName', TypeEditModelSaleLead.Contact); resetContacts(); refreshTask.emit()"
                [usingMessageParent]="true" [removePaddingBottom]="true" [widthDisplay]="'100%'" matTooltip="Last name"
                matTooltipPosition="left" [enterToSubmit]="true" [escapeToCancel]="true">
            </app-inline-edit>
        </div>
        <div style="width: calc((100% - 110px)/3)" fxLayout="column">
            <span fxFlex="100" class="limit-line label-inline" style="--line: 1; padding: 0">
                Title
            </span>
            <div>
                <app-inline-edit [inputData]="title"
                    (handleSave)="editAccountContact($event, 'title', TypeEditModelSaleLead.SaleLead)"
                    [usingMessageParent]="true" [removePaddingBottom]="true" [widthDisplay]="'100%'" matTooltip="Title"
                    matTooltipPosition="left" [enterToSubmit]="true" [escapeToCancel]="true">
                </app-inline-edit>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #popupEditor let-data>
    <mat-dialog-content style="height: 45vh; overflow-x: hidden;">
        <div style="min-height: 40vh; width: calc(60vw - 48px);">
            <quill-editor #quillEditor class="content-editor" [modules]="editorOptions"
                (onEditorCreated)="setFocus($event, data)" [ngModel]="data"
                (onContentChanged)="contentQuillEditor = $event.html">
            </quill-editor>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button (click)="closeDialogEditor()">Cancel</button>
        <button mat-raised-button color="primary" class="float-right" (click)="saveEditor()">
            <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
        </button>
    </mat-dialog-actions>
</ng-template>

<ng-template #actionButtonTemplate>
    <div fxFlex="50" fxLayoutGap="1rem" fxLayoutAlign="start start" style="margin-left: -8px">
        <button #sendMailBtn="nbPopover" mat-icon-button matTooltip="Send an email" [nbPopover]="contactSelectPopEmail"
            nbPopoverPlacement="bottom" nbPopoverTrigger="noop" (click)="nbMailPopover.show()">
            <mat-icon class="material-icons-outlined green-color">email</mat-icon>
        </button>
        <button #makeCallBtn="nbPopover" mat-icon-button matTooltip="Make a call" [nbPopover]="contactSelectPopCall"
            nbPopoverPlacement="bottom" nbPopoverTrigger="noop" (click)="makeCallPopover.show()"
            [disabled]="isCallLoading || isCalling" [nbSpinner]="isCallLoading || isCalling">
            <mat-icon class="material-icons-outlined green-color">call</mat-icon>
        </button>
        <button *nbIsGranted="['view', 'send-sms-button']" #sendSMSBtn="nbPopover" mat-icon-button matTooltip="Send SMS"
            [nbPopover]="contactSelectPopSMS" nbPopoverPlacement="bottom" nbPopoverTrigger="noop"
            (click)="sendSMSPopover.show()">
            <mat-icon class="material-icons-outlined green-color">sms</mat-icon>
        </button>
        <!-- <button mat-icon-button matTooltip="Add task" debouncedClick (throttleClick)="createTask()">
            <mat-icon class="material-icons-outlined green-color">add_task</mat-icon>
        </button> -->
    </div>
</ng-template>
<!--#endregion-->

<ng-template #copied>
    <span class="copied">
        Copied!!
    </span>
</ng-template>

<ng-template #areaDescriptionNote>
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">
        <div fxFlex="48" fxFlex.lt-md="100" fxLayout="row wrap" fxLayoutAlign="start start">
            <div fxFlex="100" fxLayoutAlign="start end">
                <ng-container [ngTemplateOutlet]="defaultProperty" [ngTemplateOutletContext]="{
                    icon: 'paid', toolTip: 'Annual Revenue',
                    property: 'annualRevenue',
                    value: saleLeadModel.annualRevenue,
                    typeModel: TypeEditModelSaleLead.SaleLead,
                    validProp: validProperties.annualRevenue,
                    pipe: numberPipe,
                    prefix: prefixCurrency,
                    fxFlex: 50
                }"></ng-container>
                <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                    icon: 'apartment', toolTip: 'Lead source',
                    property: 'leadSource',
                    value: saleLeadModel.leadSource,
                    typeModel: TypeEditModelSaleLead.SaleLead,
                    observable :dynamicContentLeadSourceAPI,
                    searchProp: 'content',
                    flexDefault: 50,
                    flexSm: 50
                }"></ng-container>
            </div>
            <div fxFlex="100" fxLayoutAlign="start end">
                <ng-container *nbIsGranted="['view', 'linked-in-leads']" [ngTemplateOutlet]="defaultProperty"
                    [ngTemplateOutletContext]="{
                icon: 'linkedin', toolTip: 'LinkedIn',
                property: 'linkedIn',
                value: saleLeadModel.saleLeadsContact.linkedIn,
                typeModel: TypeEditModelSaleLead.Contact,
                fxFlex: 50,
                externalIcon: true
            }"></ng-container>
                <!-- <ng-container [ngTemplateOutlet]="autocompleteInline" [ngTemplateOutletContext]="{
                    icon: 'apartment', toolTip: 'Lead source',
                    property: 'leadSource',
                    value: saleLeadModel.leadSource,
                    typeModel: TypeEditModelSaleLead.SaleLead,
                    observable :dynamicContentLeadSourceAPI,
                    searchProp: 'content'
                }"></ng-container> -->
                <ng-container [ngTemplateOutlet]="actionButtonTemplate"></ng-container>
            </div>
            <div fxFlex="100" fxLayoutAlign="start start">
                <!-- <ng-container *nbIsGranted="['view', 'linked-in-leads']" [ngTemplateOutlet]="defaultProperty"
                    [ngTemplateOutletContext]="{
                icon: 'linkedin', toolTip: 'LinkedIn',
                property: 'linkedIn',
                value: saleLeadModel.saleLeadsContact.linkedIn,
                typeModel: TypeEditModelSaleLead.Contact,
                fxFlex: 50,
                externalIcon: true
            }"></ng-container> -->
            </div>
            <div fxFlex="100" fxLayout="column" fxLayoutAlign="start start" class="pr-2">
                <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
                    Description
                </span>
                <h6 fxLayout="row" fxLayoutAlign="start start" class="h6-custom" matTooltip="Description"
                    matTooltipPosition="left" style="width: 100%">
                    <span class="material-icons-outlined blue-color mr-1" style="font-size: 24px;">description</span>
                    <span class="hover-popup-editor limit-line" style="--line: 2; margin-top: 2px;"
                        (click)="openComponentEditor('saleLeadsContact', 'description')">
                        {{convertHtmlToNormalStyle(saleLeadModel.saleLeadsContact.description
                        ?? 'Click to add description')
                        || 'Click to add description'}}
                    </span>
                </h6>
                <!-- <ng-container [ngTemplateOutlet]="actionButtonTemplate"></ng-container> -->
                <div style="max-height: 5.125rem; min-height: 0; width: 100%; overflow: auto; overflow-x: hidden;">
                    <app-auto-complete-tag [(tagsList)]="saleLeadModel.tagList" [profileId]="id"
                        [(profileDetail)]="saleLeadModel" [readonlyTags]="false" (onChange)="refreshData()">
                    </app-auto-complete-tag>
                </div>
            </div>
        </div>

        <div fxFlex="52" fxFlex.lt-md="100" fxLayout="row wrap" fxLayoutAlign="start start" class="area-last-note">
            <!-- <ng-container [ngTemplateOutlet]="actionButtonTemplate" [ngTemplateOutletContext]="{}">
            </ng-container> -->
            <!-- <ng-container *ngIf="lastNote; else blankLastNote">
                <nb-card class="body-last-note-card" [nbSpinner]="overlayNoteDetails?.isLoading | async"
                    (click)="noteService.pushNoteDetails(lastNote, saleLeadModel.displayName)">
                    <nb-card-header fxLayout="row" fxLayoutAlign="space-between center"
                        style="padding: 0 1rem; background-color: #f5da65;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <button mat-icon-button debouncedClick [throttleTime]="500" (throttleClick)="noteService.pushNoteDetails({
                                referenceId: id,
                                referenceType: 'LEADS',
                                referenceName: saleLeadModel.displayName
                            })">
                                <mat-icon>add</mat-icon>
                            </button>
                            <nb-user *ngIf="lastNote.ownerId" [onlyPicture]="true" color="#8f9bb37a"
                                [matTooltip]="lastNote.user?.userName" [name]="lastNote['ownerNote']"
                                [picture]="lastNote['avatarNote']" size="small">
                            </nb-user>
                            <span class="limit-line" style="--line: 1;" [matTooltip]="lastNote.title"
                                matTooltipPosition="left">
                                Last note: {{lastNote.title}}
                            </span>
                        </div>

                        <span fxFlex="20" class="limit-line"
                            style="--line: 1; width: auto; font-weight: normal; text-align: end;"
                            matTooltip="Last created" matTooltipPosition="left">
                            {{ (lastNote.dateCreated | date: 'short') || 'None' }}
                        </span>
                    </nb-card-header>
                    <nb-card-body style="background-color: #fef9b7;" [innerHTML]="lastNote.note">
                    </nb-card-body>
                </nb-card>
            </ng-container> -->
            <app-crm-inline-note #inlineNote [relationshipId]="id" [model]="saleLeadModel" [isNoteRow]="true"
                [isDisableEditorArea]="true" typeRelationship="LEADS" class="w-100 h-100"
                style="height: 250px !important;" (refreshGridNote)="refreshNoteTab()"
                [onNoteInlineChanged]="onNoteInlineChanged">
            </app-crm-inline-note>
        </div>
    </div>
</ng-template>

<ng-template #blankLastNote>
    <app-blank-page fxFlex="auto" [linkSrc]="'assets/images/notetaking.gif'"
        [message]="'Do you wish to add a new note?'" [contentTemplate]="contentEmptyLastNote">
    </app-blank-page>
</ng-template>

<ng-template #contentEmptyLastNote>
    <span class="mb-2"><strong>Do you wish to add a new note?</strong></span>
    <button class="mb-2" mat-stroked-button color="primary" (throttleClick)="noteService.pushNoteDetails({
        referenceId: id,
        referenceType: 'LEADS',
        referenceName: saleLeadModel.displayName
    })" debouncedClick [throttleTime]="500">
        Add new
        <mat-icon>add</mat-icon>
    </button>
</ng-template>

<ng-template #accountOwner>
    <div fxFlex="24" fxFlex.lt-sm="48" *ngIf="ownerModel; else avatarSkeleton" fxLayout="column" class="mb-2 pr-2"
        matTooltip="Owner" matTooltipPosition="left">
        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
            Owner
        </span>
        <div [style.color]="ownerModel?.id ? '' : 'rgb(0 0 0 / 60%)'">
            <nb-user class="custom-avatar-outline" fxFlex="30px" size="small" [onlyPicture]="true"
                [picture]="ownerModel?.pictureURL" [name]="ownerModel?.fullName">
            </nb-user>
            <app-search-edit-inline fxFlex="1 1 calc(100%-30px)" [inputData]="ownerModel?.id
                ? (ownerModel?.userName + ' (' + ownerModel?.fullName + ')')
                : 'Unassigning'" [apiSearching]="apiGetSearchOwner" [widthDisplay]="'100%'"
                [externalTemplate]="optionOwnerTemplate"
                (handleSave)="editAccountContact($event.id, 'ownerId', TypeEditModelSaleLead.SaleLead)">
            </app-search-edit-inline>
        </div>
    </div>
</ng-template>

<ng-template #avatarSkeleton>
    <ngx-skeleton-loader fxFlex="24" fxFlex.lt-sm="48" class="pr-2" count="1" appearance="line" animation="progress"
        [theme]="{ 'margin-bottom': '0px', 'margin-top': '8px', 'height': '2.25rem' }">
    </ngx-skeleton-loader>
</ng-template>

<ng-template #optionOwnerTemplate let-item="item">
    <div fxLayout="row" fxLayoutAlign="start end">
        <div style="font-size:14px" fxFlex="40" class="limit-line" style="--line:1;font-size:12px; margin-right:15px;">
            <b>{{item?.userName || "No name display"}}</b> ({{item?.fullName || ""}})
        </div>
        <div class="text-secondary limit-line" [matTooltip]="" style="--line:1;font-size:12px; margin-right:15px ;"
            fxFlex="35">
            {{item?.email || "No email display" }}
        </div>
        <div class="text-secondary limit-line" style="--line:1;font-size:12px" fxFlex="25">
            {{(item?.phoneNumber | phoneFormat) || "No phone display" }}
        </div>
    </div>
</ng-template>

<ng-template #autocompleteInline let-icon="icon" let-toolTip="toolTip" let-property="property" let-value="value"
    let-observable="observable" let-searchProp="searchProp" let-typeModel="typeModel" let-validProp="validProp"
    let-disabled="disabled" let-pipe="pipe" let-prefix="prefix" let-addition="addition" let-flexDefault="flexDefault"
    let-flexSm="flexSm" let-suffixFunc="suffixFunc" let-isAscii="isAscii">
    <div [fxFlex]="flexDefault ?? 50" [fxFlex.lt-sm]="flexSm ?? 50" fxLayout="column" class="mb-2 pr-2"
        [matTooltip]="toolTip" matTooltipPosition="left">
        <span fxFlex="100" class="limit-line label-inline" style="--line: 1;">
            {{toolTip}}
        </span>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="flex-start center">
            <span fxFlex="30px" class="material-icons-outlined blue-color" color="primary" style="font-size: 24px;">
                {{icon}}
            </span>
            <app-auto-complete-inline-edit fxFlex="1 1 calc(100%-30px)" [inputData]="value"
                (handleSave)="addition? saveIndividualProfileAdditionDetail($event, property): editAccountContact($event, property, typeModel); suffixFunc ? suffixFunc() : ''"
                [removePaddingBottom]="true" [widthDisplay]="'100%'"
                [validatorValue]="validProp ? validProp.valid : null"
                [messageError]="validProp ? validProp.message : null" [usingMessageParent]="true" [enterToSubmit]="true"
                [escapeToCancel]="true" [readonlyInline]="disabled ?? false" [pipe]="pipe" [prefix]="prefix"
                [searchArrayAPI]="observable" [searchProperty]="searchProp" [isAscii]="isAscii || false">
            </app-auto-complete-inline-edit>
        </div>
    </div>
</ng-template>
<!-- use later for email and call -->
<ng-template #contactSelectPopEmail>
    <app-pop-over-select-contacts [id]="id" [type]="'LEADS'" [(contactList)]="contacts"
        [primaryContactId]="saleLeadModel.accountContactId" [action]="'email'"
        (onSelectedContact)="getSelectedContactMail($event)" [popover]="nbMailPopover" (refreshData)="refreshData()">
    </app-pop-over-select-contacts>
</ng-template>

<ng-template #contactSelectPopCall>
    <app-pop-over-select-contacts [id]="id" [type]="'LEADS'" [(contactList)]="contacts"
        [primaryContactId]="saleLeadModel.accountContactId" [action]="'call'"
        (onSelectedContact)="getSelectedContactCall($event)" [popover]="makeCallPopover" (refreshData)="refreshData()">
    </app-pop-over-select-contacts>
</ng-template>

<ng-template #contactSelectPopSMS>
    <app-pop-over-select-contacts [id]="id" [type]="'LEADS'" [(contactList)]="contacts"
        [primaryContactId]="saleLeadModel.accountContactId" [action]="'call'" [actionTitle]="'send SMS'"
        (onSelectedContact)="openSMS($event)" [popover]="sendSMSPopover" (refreshData)="refreshData()">
    </app-pop-over-select-contacts>
</ng-template>

<ng-template #externalTemplate>
    <ng-container [ngTemplateOutlet]="data.externalTemplate? data.externalTemplate : ''"
        [ngTemplateOutletContext]="{row: data.row}"></ng-container>
</ng-template>

<ng-template #addFilesMenu class="column d-flex flex-column align-items-start">
    <input #fileInput hidden="true" type="file" onclick="this.value=null" (change)="addSaleLeadFile($event)" />
    <!-- [extension]='{"extensions":["doc", "docx", "xls", "xlsx", "ppt", "pdf", "html", "zip", "csv", "txt"]}'
        accept=".doc,.docx,.xls,.xlsx,.ppt,.pdf,.html,.zip,.csv,.txt" /> -->

    <button mat-flat-button class="col float-start" debouncedClick [throttleTime]="500" matTooltipPosition="below"
        (throttleClick)="fileInput.click()">
        <mat-icon style="color: #007100;">add</mat-icon> Add New Files
    </button>

    <hr class="custom-divider">
    <button mat-flat-button class="col d-flex flex-row" debouncedClick [throttleTime]="500" matTooltipPosition="below"
        (click)="openURLDialogRef()">
        <mat-icon style="color: #007100;">add</mat-icon> Add URL
    </button>
</ng-template>
