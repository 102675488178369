import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getColorLastContact'
})
export class GetColorLastContactPipe implements PipeTransform {

	transform(value: string): string {
		if (value == "Never Contacted") return "#ffa3a3";

		let numberOfDays = (new Date().getTime() - new Date(value).getTime()) / (1000 * 60 * 60 * 24);

		if (numberOfDays <= 7) return "#C8E6C9";
		if (numberOfDays <= 30) return "#F5DA65";
		return "#FFCE85";
	}

}
