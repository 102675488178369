import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';

import { ChangeDetectorRef, Component, Inject, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
import { email, pattern, prop, required, RxFormBuilder, toInt } from '@rxweb/reactive-form-validators';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { UserModel } from 'src/app/modules/admin/user-management/user-model';
import { UserService } from 'src/app/modules/admin/user-management/user.service';
import { TblActionType } from '../../enums/tbl-action-type.enum';
import { ActivityLogService } from '../../services/activity-log.service';
import { MyErrorStateMatcher } from '../search-edit-inline/search-edit-inline.component';
@Component({
  selector: 'app-add-external-activity-log',
  templateUrl: './add-external-activity-log.component.html',
  styleUrls: ['./add-external-activity-log.component.scss']

})
export class AddExternalActivityLogComponent implements OnInit,OnChanges {
  externalLog: ExternalActivityLogModel;
  frmExternalLog: FormGroup;
  frmControl = new FormControl();
  matcher = new MyErrorStateMatcher();
  isLoading: boolean = false;
  actionChange;
  // Value Place Holder for log
  placeLogInfo1;
  placeLogInfo2;
  placeLogInfo3;
  // Value label for log
  labelLogInfo1;
  labelLogInfo2;
  labelLogInfo3;
  // Show or hide log
  isHideLogInfo2:boolean = false;
  isHideLogInfo1:boolean = false;
  isHideLogInfo3:boolean = false;
  isHideAction:boolean = true;
  isSelectUser:boolean = false;
  required:boolean = true;
  user: any;
  users: UserModel[] = [];
  userList = [];
  asteriskSub = null;
  userNameImage = '';
  filteredOptions: Observable<UserModel[]>;
  infoActivity = {
    label:'Email',
    control:'email'
  }
  actions: {} = {
    email: ['Send', 'Read', 'Click', 'Unsubscribed', 'Deferred', 'Reply', 'Bounce'],
    phoneNumber: ['Call End', 'Left Voice Mail']
  };
  private destroy$: Subject<void> = new Subject<void>();
  constructor(
    private dialogRef: MatDialogRef<AddExternalActivityLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdref: ChangeDetectorRef,
    private frmBuilder: RxFormBuilder,
    private dialog: MatDialog,
    private activityLogService: ActivityLogService,
    private toast: NbToastrService,
    private authService: NbAuthService,
    private userService: UserService,
  ) {

    this.externalLog = Object.assign({}, new ExternalActivityLogModel(), this.externalLog, data);
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) this.user = token.getPayload();
      });
    this.userService.allUserList().subscribe(res => { this.users = res;});
  }
  ngOnInit(): void {
    this.frmExternalLog = this.frmBuilder.formGroup(ExternalActivityLogModel, this.externalLog);
    this.userService.getAllUser();
    this.userService.allUserList().pipe(takeUntil(this.destroy$)).subscribe(e => {
      this.userList = e;
    });
    this.filteredOptions = this.frmControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.userName)),
      map(userName => (userName ? this._filter(userName) : this.users.slice())),
    );
  }
  ngOnChanges(changes: SimpleChanges):void{
      console.log("")
  }
  async saveData() {
    if (this.frmExternalLog?.value) {
      var data: ExternalActivityLogModel = Object.assign({}, this.frmExternalLog.value, { userName: this.user['userName'], });
      var saveLogInfo = null;
      if(this.frmExternalLog.controls.logInfo1.value && this.isSelectUser)
      {
        var getValueLogInfo1 = this.frmExternalLog.controls.logInfo1.value;
        var checkUserName = this.userList.find(x=>x.userName.toLowerCase() == getValueLogInfo1.toLowerCase()) ?? null;
        if(checkUserName){
          saveLogInfo = getValueLogInfo1;
          data.userName = getValueLogInfo1 ?? this.user['userName'];
        }
      }
      // if(this.isSelectUser && this.frmExternalLog.controls.logInfo1.value){
      //   data.userName = this.frmExternalLog.controls.logInfo1.value ?? this.user['userName'];
      // }
      switch (data.subObjName) {
        // handle event mail
        case "ExternalEmail":
          if(data.action.toLowerCase() == "send"){
            data.logInfo1 = `title;${data.logInfo2 ?? ''};emailsend;${data.email ?? 'unknown'}`
            data.logInfo2 = `email;${saveLogInfo ?? 'unknown'};body;`
            data.subAction = "SendMailImmediately";
          } else if(data.action.toLowerCase() == "reply"){
            data.action = "ReplyDetail";
            var saveLogInfo1 = data.logInfo1;
            data.logInfo1 = `title;${data.logInfo2 ?? ''};emailsend;${data.email ?? 'unknown'}`
            data.logInfo2 = `emailreceived;${saveLogInfo1 ?? 'unknown'};body;`
          }
          else{
            data.logInfo2 = `outbound_reference;${data.logInfo1 ?? ''};;${data.logInfo2 ?? ''}`
            data.logInfo1 = data.email;
          }
          break;

          // handle event call
        case "ExternalCall":
          if(data.action){
            if(data.action == "Call End"){
              data.action = "CallEnd";
            }else if(data.action == "Left Voice Mail"){
              data.action = "LeftVoiceMail";
            }
          }
          if(data.action.toLowerCase() == "callend"){
            if(!data.callTime){
              data.callTime = "00:00:00";
            }
            else{
              const date = new Date(null);
              date.setSeconds(parseInt(data.callTime)); // specify value for SECONDS here
              data.callTime = date.toISOString().slice(11, 19);
            }
            data.subObjId = data.phoneNumber;
            data.logInfo1 = `userCall;${saveLogInfo ?? 'unknown'};callEvent;${data.action};duration;${data.callTime}`;
            data.logInfo2 = `{callRecipient;${data.logInfo2 ?? 'unknown'};"result":"success"}`
            data.action = "Call";
          }
          // else if(data.action.toLowerCase() == "leftvoicemessage"){
          //   data.subObjId = data.phoneNumber;
          //   data.logInfo2 = `user;${data.logInfo2}`;
          //   data.logInfo1 = `userCall;${data.logInfo1};callEvent;${data.action}`;
          //   data.action = `AMDCall`;
          // }
          else{
            data.subObjId = data.phoneNumber;
            data.logInfo2 = `user;${data.logInfo2 ?? 'unknown'};"result":"success"`;
            data.logInfo1 = `userCall;${saveLogInfo ?? 'unknown'};callEvent;${data.action ??''}`;
          }
          break;
        case 'ExternalOther':
            data.action = "Other";
            break;
        default:
          break;
      }

      this.isLoading = true;
      var savingResult = await this.activityLogService.addExternalActivityLog(data).toPromise();
      if (savingResult.result) {
        this.toast.success("Save activity log success!", "Success");
        this.dialogRef.close(true);

        if (data.subObjName == 'ExternalOther') {
          const confirmDialog = this.dialog.open(ConfirmModalComponent, {
            disableClose: true,
            autoFocus: false,
            data: {
              message: "Do you want to update the last contact?"
            }
          })

          const confirmResult = await confirmDialog.afterClosed().toPromise();
          if (confirmResult) {
            var updateLastContactResult = await this.activityLogService.updateLastContact(savingResult.result).toPromise();
            if (!updateLastContactResult.result) this.toast.danger("Can't update the last contact");
          }
        }
      }
      else {
        this.toast.danger("Save activity log fail!", "Error");
      }
      this.isLoading = false;
    }
  }

  ngOnDestroy(): void {
    if (this.asteriskSub != null) {
      this.asteriskSub.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
  getSelectActivityLog(data){
    if(data.value){
      this.isHideLogInfo1 = false;
      this.isHideLogInfo2 = false;
      this.isHideLogInfo3 = false;
      this.isHideAction = true;
      this.isSelectUser = false;
      this.frmExternalLog.controls.logInfo1.setValue(null);
      this.frmExternalLog.controls.logInfo2.setValue(null);
      this.frmExternalLog.controls.callTime.setValue(null);
      this.frmExternalLog.controls.action.setValue(null);
      this.frmExternalLog.controls.phoneNumber.setValue(null);
      this.frmExternalLog.controls.email.setValue(null);
      switch(data.value.toLowerCase()){
        case 'externalemail':
          this.infoActivity.label = 'Email';
          this.infoActivity.control = 'email';
          return;
        case 'externalcall':
          this.infoActivity.label = 'Phone Number';
          this.infoActivity.control = 'phoneNumber';
          return;
        case 'externalother':
          this.infoActivity.label = 'Type Custom Log';
          this.infoActivity.control = 'other';
          this.isHideAction = false;
          this.required = false;
          return;
        default:
        break;
      }
    }
  }

  getSelectChange(data){
    this.isHideLogInfo1 = true;
    this.isHideLogInfo2 = true;
    this.isHideLogInfo3 = false;
    this.isSelectUser = false;
    this.frmExternalLog.controls.logInfo1.setValue(null);
    this.frmExternalLog.controls.logInfo2.setValue(null);
    this.frmExternalLog.controls.callTime.setValue('');
    if(data){
      this.actionChange = data.value;
      if(this.actionChange){
        switch (this.actionChange.toLowerCase()){
          case 'send':
            this.isSelectUser = true;
            this.labelLogInfo1 = `User Send`;
            this.placeLogInfo1 = `Select User Send`;
            this.labelLogInfo2 = `Subject Name`;
            this.placeLogInfo2 = `Enter subject name`;
          break;
          case 'read':
            this.labelLogInfo1 = `Title`;
            this.placeLogInfo1 = `Enter title`;
            this.isHideLogInfo2 = false;
          break;
          case 'click':
            this.labelLogInfo1 = `Link`;
            this.placeLogInfo1 = `Enter Link`;
            this.isHideLogInfo2 = false;
          break;
          case 'unsubscribed':
            this.labelLogInfo1 = `Message`;
            this.placeLogInfo1 = `Enter Message`;
            this.isHideLogInfo2 = false;
          break;
          case 'deferred':
            this.labelLogInfo1 = `Message`;
            this.placeLogInfo1 = `Enter Message`;
            this.isHideLogInfo2 = false;
          break;
          case 'reply':
            this.labelLogInfo1 = `User`;
            this.placeLogInfo1 = `Select User`;
            this.labelLogInfo2 = `Subject Name`;
            this.placeLogInfo2 = `Enter subject name`;
            this.isSelectUser = true;
          break;
          case 'bounce':
            this.isHideLogInfo1 = false;
            this.isHideLogInfo2 = false;
          break;
          // case 'call':
          //   this.labelLogInfo1 = `Caller`;
          //   this.labelLogInfo2 = `Call Recipient`;
          //   this.placeLogInfo1 = `Enter caller`;
          //   this.placeLogInfo2 = `Enter call recipient`;
          // break;
          // case 'leavemessage':
          //   this.labelLogInfo1 = `User`;
          //   this.placeLogInfo1 = `Enter user`;
          //   this.labelLogInfo2 = `Call Receiver`;
          //   this.placeLogInfo2 = `Enter call receiver`;
          // break;
          case 'call end':
            this.isSelectUser = true;
            this.isHideLogInfo3 = true;
            this.labelLogInfo1 = `User Calling From`;
            this.placeLogInfo1 = `Select User Calling From`;
            this.isHideLogInfo2 = false;
            // this.labelLogInfo2 = `Calling To`;
            // this.placeLogInfo2 = `Enter Calling To Number`;
            this.labelLogInfo3 = `Duration`;
            this.placeLogInfo3 = `Enter Duration (seconds). Example : 90`
          break;
          case 'left voice mail':
            this.isSelectUser = true;
            this.labelLogInfo1 = `User Calling From`;
            this.placeLogInfo1 = `Select User Calling From`;
            this.isHideLogInfo2 = false;
            // this.labelLogInfo2 = `Calling To`;
            // this.placeLogInfo2 = `Enter Calling To Number`;
          break;

        default:
        break;

        }
      }
    }
  }
  onSelected(select: MatAutocompleteSelectedEvent) {
    this.frmControl.setValue(this.users.find(x => x.id == select.option.value));
  }
  displayFn(user: UserModel): string {
    return user && user.userName ? user.userName : '';
  }
  private _filter(name: string): UserModel[] {
    // value could be user name or id
    const filterValue = name.toLowerCase();
    this.frmExternalLog.controls.logInfo1.setValue(name);
    return this.users.filter(option => option.userName.toLowerCase().includes(filterValue) || option.id.toLowerCase().includes(filterValue));
  }
}

export class ExternalActivityLogModel {
  @prop()
  activityType: string = "External";
  @prop()
  objName: string;
  @prop()
  objId: string;
  @required()
  subObjName: string = "ExternalEmail";
  @prop()
  subObjId: string;
  @required({ conditionalExpression: function () { return this.subObjName == "ExternalEmail" } })
  @email({ message: "Email invalid" })
  email: string;

  @required({ conditionalExpression: function () { return this.subObjName == "ExternalCall" } })
  @pattern({ expression: { phone: /^[(]?(\d|\+)\s?(?:[\d-.x\s()]*)$/ }, message: "Phone number invalid" })
  phoneNumber: string;

  @required({ conditionalExpression: function () { return this.subObjName !== "ExternalOther" } })
  action: string;

  @required({ conditionalExpression: function () { return this.subObjName == "ExternalOther" } })
  @prop()
  logInfo1: string;
  @prop()
  logInfo2: string;
  @prop()
  @required()
  dateCreated: Date | string | null = new Date();

  actionDialog: TblActionType;
  userName: string;
  @prop()
  callTime:string;
  subAction: string;
}
