import { digit, email, maxNumber, numeric, NumericValueType, pattern, prop, required } from "@rxweb/reactive-form-validators";

export class SaleAccountViewModel {
    profileId: string;

    @prop()
    @required()
    accountName: string;

    parentId: string;

    @prop()
    @pattern({
        // expression: { website: /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/ } ,
        expression: { website: /^\s*(https?:\/\/)?(localhost|(?!.*\.\.)([\w-]+\.)+[\w-]{1,})(:\d+)?(\/[^\s]*)?\s*$/ } ,
        message: 'Website URL is not valid'
    })
    website: string;

    @prop()
    description: string;

    //@pattern({ expression: { phone: /^[(]?(\d|\+)\s?(?:[\d-.x\s()]*)$/ } ,message: 'This field is not valid' })
    //@required()
    @prop()
    phoneNumber: string;

    @prop()
    @email()
    email: string;

    @prop()
    @numeric({acceptValue:NumericValueType.Both  ,allowDecimal:true , message: 'Only numberic is allowed'})
    annualRevenue: number;

    @prop()
    @digit()
    @maxNumber({value: 2147483647})
    employees: number;

    @prop()
    contactTypeLst: number | number[];

    @prop()
    state: string;
    @prop()
    country:  string;
    @prop()
    accountSubContactType: number;

    @prop()
    primaryPhoneExt: string;
}
