<div *ngIf="isDialog == true" class="d-flex flex-wrap" mat-dialog-title
  style="margin: unset; padding: 1px 10px 1px 28px">
  <!-- <button mat-button color="primary" (click)="saveData()" [disabled]="!frmUser.valid">
  <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
</button> -->
  <!-- <button mat-button color="primary" (click)="closeDialog()()">
      <mat-icon>clear</mat-icon>Cancel
  </button> -->
  <button mat-icon-button aria-label="Close the dialog" (click)="closeDialog()" class="ml-auto">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="content-height">
  <div class="col-lg-12">
    <!-- <mat-card-title class="p-2" mat-dialog-title> Location </mat-card-title> -->
    <div *ngFor="
        let formInstance of formLocationLst;
        index as i;
        trackBy: trackByFn
      ">
      <form class="column" [formGroup]="formInstance">
        <mat-card key="i">
          <div class="row d-flex-row justify-content-between p-2">
            <div class="h6">
              <!-- <span *ngIf="formInstance.get('primaryLocation').value == true">
                <mat-icon style="color: #008000;" matTooltip="This is a primary location">
                  check_circle
                </mat-icon>
              </span> -->
              <span *ngIf="!isUpdateAction; else updateTitle">
                Location {{ i + 1 }}
              </span>
              <ng-template #updateTitle>
                Location {{ locationIndex }}
              </ng-template>
              <button *ngIf="
                  formInstance.get('primaryLocation').value == false ||
                  formInstance.get('primaryLocation').value == null
                " mat-button color="primary" (click)="setPrimaryLocation(i)" [attr.disabled]="loading"
                [hidden]="!activeSetPrimary"
                matTooltip="When this button clicked, the location will be set as a primary">
                Set Primary
              </button>
              <!-- <button *ngIf="formInstance.get('primaryLocation').value == true;" mat-button color="warn"
                [attr.disabled]='loading' (click)="removePrimaryLocation(i)"
                matTooltip="By pressing this button, the location will be removed the primary">Remove
                Primary</button> -->

              <!-- DISABLE CANCEL PRIMARY BUTTON -->

              <mat-chip style="background-color: #007100" *ngIf="formInstance.get('primaryLocation').value == true"
                [attr.disabled]="loading" matTooltip="By pressing this button, the location will be removed the primary"
                selected [removable]="true" (removed)="removePrimaryLocation(i)">Primary
                <mat-icon matChipRemove *ngIf="locationtLst[i].primaryLocation == false">cancel</mat-icon>
              </mat-chip>
            </div>
            <div class="row" style="padding-right: 30px">
              <button *ngIf="isAddMoreBtn" mat-raised-button color="primary" [attr.disabled]="loading"
                (click)="duplicate(formInstance); isChange = true">
                <mat-icon>content_copy</mat-icon>Duplicate
              </button>
              <div style="margin-left: 20px" *ngIf="locationtLst.length > 1 && i !== 0"></div>
              <button mat-raised-button color="warn" [attr.disabled]="loading" (click)="onDeleteLocationBtn(i)"
                *ngIf="locationtLst.length > 1 && i !== 0">
                <mat-icon>remove</mat-icon>Remove
              </button>
            </div>
          </div>
          <div class="row col-lg-12">
            <mat-form-field appearance="standard" class="col-lg-12 col-md-6">
              <mat-label>Address 1</mat-label>
              <input matInput autocomplete="off" formControlName="address" [readonly]="readonlyLocation" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="col-lg-12 col-md-6">
              <mat-label>Address 2</mat-label>
              <input matInput autocomplete="off" formControlName="address2" [readonly]="readonlyLocation" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>City</mat-label>
              <input matInput autocomplete="off" formControlName="city" trim="blur" [readonly]="readonlyLocation" />
              <mat-error *ngIf="
                  formInstance.get('city').errors &&
                  (formInstance.controls.city.dirty ||
                    formInstance.controls.city.touched)
                ">
                Please enter a valid city
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>State/Province</mat-label>
              <input matInput autocomplete="off" formControlName="state" [readonly]="readonlyLocation" />
            </mat-form-field> -->
            <app-dynamic-content-form class="col-lg-4 col-md-6" style="width: 100%" label="State/Province"
              [options]="stateOptions" [displayKey]="'content'" [control]="formInstance.controls.state">
            </app-dynamic-content-form>
            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>Country</mat-label>
              <input #countryAutocompleteTrigger type="text" placeholder="Select your Country" required matInput
                [matAutocomplete]="auto" formControlName="country" [readonly]="readonlyLocation" />
              <mat-autocomplete #auto="matAutocomplete" (closed)="checkCountryMatchOnBlur(formInstance)">
                <ng-container *ngIf="countries != null; else loadingDataCountries">
                  <mat-option *ngFor="let country of locationtLst[i].countryObservable | async" [value]="country.value">
                    {{ country.value }}
                  </mat-option>
                </ng-container>
                <ng-template #loadingDataCountries>
                  <mat-option [nbSpinner]="true"></mat-option>
                </ng-template>
              </mat-autocomplete>
              <mat-error *ngIf="
                  formInstance.get('country').errors &&
                  (formInstance.controls.country.dirty ||
                    formInstance.controls.country.touched)
                ">
                Please enter a valid country
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>Postal/Zip code</mat-label>
              <input matInput autocomplete="off" formControlName="zipcode" [readonly]="readonlyLocation" />
              <mat-error *ngIf="formInstance.controls.zipcode.errors 
              && (formInstance.controls.zipcode.dirty ||formInstance.controls.zipcode.touched)">
              Please enter a valid postal/zip code</mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>Region</mat-label>
              <input matInput autocomplete="off" formControlName="region" [readonly]="readonlyLocation" />
            </mat-form-field>
            <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
              <mat-label>Nearest major city</mat-label>
              <input matInput autocomplete="off" formControlName="nearestBigCity" [readonly]="readonlyLocation" />
            </mat-form-field>
            
            <ng-container *ngIf="crmMode !='1'">
              <ng-container *ngIf="!isUseLocationMajorMockup;else locationMajorMockup">
                <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
                  <mat-label>Major City</mat-label>
                  <input matInput autocomplete="off" formControlName="majorCity" [readonly]="readonlyLocation" />
                </mat-form-field>
                <app-dynamic-content-form class="col-lg-4 col-md-6" label="Major State"
                [options]="majorStateOptions" [displayKey]="'content'" [control]="formInstance.controls.majorState">
                </app-dynamic-content-form>
                <!-- <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
                  <mat-label>Major Country</mat-label>
                  <input matInput autocomplete="off" formControlName="majorCountry" [readonly]="readonlyLocation" />
                </mat-form-field> -->
                <app-dynamic-content-form class="col-lg-4 col-md-6" label="Major Country" [errorMessage]="'Please enter a valid country'"
                [options]="majorCountryOptions" [displayKey]="'content'" [control]="formInstance.controls.majorCountry">
                </app-dynamic-content-form>
                <mat-form-field appearance="standard" class="col-lg-4 col-md-6">
                  <mat-label>Major Zip Code</mat-label>
                  <input matInput autocomplete="off" formControlName="majorZipcode" [readonly]="readonlyLocation" />
                </mat-form-field>
              </ng-container>
              <ng-template #locationMajorMockup>
                  <!-- Major City -->
                  <app-dynamic-content-location class="col-lg-4 col-md-6" label="Major City"
                    [options]="lstDataCity" [displayKey]="'value'" [isReadOnly]="isReadOnlyCity" [errorMessage]="'Kindly choose a city from the dropdown menu'" 
                    [control]="formInstance.controls.majorCity" (selectionChange)="getChildrenFromParentLocation(i, 'city')">
                  </app-dynamic-content-location>
                  <!-- Major State -->
                  <app-dynamic-content-location class="col-lg-4 col-md-6" label="Major State" 
                    [options]="lstDataState" [displayKey]="'value'" [isReadOnly]="isReadOnlyState" [errorMessage]="'Kindly choose a state from the dropdown menu'" 
                    [control]="formInstance.controls.majorState" (selectionChange)="getChildrenFromParentLocation(i, 'state')">
                  </app-dynamic-content-location>
                  <!-- Major Country -->
                  <app-dynamic-content-location class="col-lg-4 col-md-6" label="Major Country"
                    [options]="lstDataCountry" [displayKey]="'value'" (setupFirstLoad)="ChangeStatusReadOnlyForMajor(i, true)" [errorMessage]="'Kindly choose a country from the dropdown menu'" 
                    [control]="formInstance.controls.majorCountry " (selectionChange)="getChildrenFromParentLocation(i, 'country')">
                  </app-dynamic-content-location>
              </ng-template>
              
            </ng-container>
            <div class="col-lg-4 col-md-6 d-flex align-items-end" style="margin-bottom: 2vh;">
              <mat-checkbox class="example-margin" color="primary" formControlName="willTravel"
                matTooltip="Check this radio if you will travel some places" (change)="disableHowFar($event.checked, i)"
                [disabled]="readonlyLocation">Will travel?</mat-checkbox>
            </div>
            <mat-form-field *ngIf="formInstance.get('willTravel').value == true" appearance="standard"
              class="col-lg-4 col-md-6">
              <mat-label>How far</mat-label>
              <mat-select id="howFar{{i}}" formControlName="howFar" [disabled]="readonlyLocation">
                <mat-option *ngFor="let item of howFarLst" [value]="item.value">
                  {{ item.key }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- test custom howfar -->
            <!-- <app-how-far-dropdown *ngIf="formInstance.get('willTravel').value == true"
              [inputValue]="formInstance.get('howFar').value" [index]='i' (getHowfarResult)="setHowFar($event)">
            </app-how-far-dropdown> -->
          </div>
        </mat-card>
      </form>
      <br />
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions style="padding: 0px !important">
  <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" style="background-color: white">
    <div class="row-lg-8">
      <!-- <button *ngIf="contactLst.length>1" mat-raised-button color="warn" class="float-right"
        (click)="onDeleteContactBtn()">
        <mat-icon>remove</mat-icon>Delete
      </button> -->
      <button *ngIf="isAddMoreBtn" mat-raised-button color="primary" class="float-right"
        (click)="onAddLocationBtn(); isChange = true" [attr.disabled]="loading"
        matTooltip="By pressing this button, new location form will be showed">
        <mat-icon>add</mat-icon>More
      </button>
    </div>
    <div class="col-lg-4">
      <button mat-raised-button color="primary" class="float-right" [nbSpinner]="loading" nbSpinnerStatus="primary"
        (click)="onSaveBtn()" [disabled]="readonlyLocation || loading">
        <mat-icon fontSet=" material-icons-outlined">save</mat-icon>Save
      </button>
    </div>
  </mat-toolbar>
</div>